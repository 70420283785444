import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { useAppContext } from '../Context'

const SkeletonCpm = ({
  baseColor,
  width,
  height,
  highlightColor,
}: {
  baseColor?: string
  width?: any
  height?: any
  highlightColor?: string
}) => {
  const { isDesktop, darkMode } = useAppContext()

  return (
    <Skeleton
      baseColor={baseColor || 'lightGray'}
      width={isDesktop ? '700px' : width || '90vw'}
      height={height || 10}
      highlightColor={darkMode === 'true' ? '#733d3d87' : highlightColor}
    />
  )
}

export default SkeletonCpm
