import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Grid, Heading } from '@enterprise-ui/canvas-ui-react'
import { useAppContext } from '../../Context'
import { useToaster } from '@enterprise-ui/canvas-ui-react'

const ErrorPage = (props: any) => {
  const navigate = useNavigate()
  const { networkError, setNetworkError } = useAppContext()
  const makeToast = useToaster()
  const errorMessage = `${
    networkError === true ? 'Error occured in the application.' : networkError
  }. Please try again`

  useEffect(() => {
    if (networkError) {
      makeToast({
        type: 'error',
        message: errorMessage,
        autoHideDuration: 200000000,
      })
    } else {
      setNetworkError(false)
    }
  }, [networkError])
  return (
    <Grid.Container align="center" justify="center">
      <Grid.Item xs={12}>
        <Heading className="hc-ta-center" size={4}>
          {errorMessage}
        </Heading>
      </Grid.Item>
      <Grid.Item>
        <Button onClick={() => navigate(0)}>Reload</Button>
      </Grid.Item>
    </Grid.Container>
  )
}

export default ErrorPage
