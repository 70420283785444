import React, { useState } from 'react'
import {
  Button,
  Grid,
  Input,
  List,
  Anchor,
  Image,
  ToastProvider,
  Heading,
  ExpandableSection,
  Divider,
  Dialog,
  FullScreen,
  Modal,
} from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, {
  CancelIcon,
  DownloadIcon,
  PencilIcon,
  TrashIcon,
} from '@enterprise-ui/icons'
import useServices from '../../services/useServices'
import { useAppContext } from '../../Context'
import { saveAs } from 'file-saver'
import constants from '../../utilities/constants'
import { getUrlParam } from '../../utilities'
import { iSchedule } from '../../types/types'
import {
  convertFileObjectToArray,
  isValidImage,
  useFileValidation,
} from './FileUploadUtilityFunctions'
import SkeletonCpm from '../SkeletonCpm'

const FileUpload = ({
  actualEndDateChanged,
}: {
  actualEndDateChanged: boolean
}) => {
  const [imageFullScreen, setImageFullScreen] = React.useState(false)
  const [fileNameEditDialog, setFileNameEditDialog] = React.useState(false)
  const [clickedFile, setClickedFile] = React.useState<any>(null)
  const makeToast = ToastProvider.useToaster()
  const newFileNameRef = React.useRef<any>(null)
  const [validating, setSelectedFilesToUpload] = useFileValidation()
  const { downloadFile, deleteFiles, getScheduleMilestonesForProject } =
    useServices()
  const {
    scheduleMilestoneToEdit,
    scheduleMilestoneFiles,
    setScheduleMilestoneFiles,
    allMilestones,
    setProjectSchedules,
    isDesktop,
  } = useAppContext()
  const projectId = getUrlParam(constants.PROJECT_PARAM)
  const locationId = getUrlParam(constants.LOCATION_PARAM)
  const [fileActionsLoader, setFileActionsLoader] = useState<any>(false)
  const fileRequired = scheduleMilestoneToEdit?.file_required ?? false
  const onUploadFiles = (event: any) => {
    if (event) {
      setSelectedFilesToUpload(
        convertFileObjectToArray(
          event.target.files,
          scheduleMilestoneToEdit,
          projectId,
          locationId,
          fileRequired,
          actualEndDateChanged,
        ),
      )
    }
  }

  const onTrashIconClick = (idx: any): any => {
    return scheduleMilestoneFiles.filter(
      (val: any, innerIdx: any) => idx !== innerIdx,
    )
  }

  const onDownloadFiles = (fileForDownload: any) => {
    try {
      downloadFile(
        constants.SCHEDULE_MILESTONE,
        fileForDownload.schedule_milestone_toss_object_key,
        fileForDownload.schedule_milestone_toss_file_name,
        fileForDownload.schedule_milestone_toss_file_content_type,
        (result: any) => {
          let fileURL = URL.createObjectURL(new Blob([result]))
          saveAs(fileURL, fileForDownload.schedule_milestone_toss_file_name)
          setFileActionsLoader(false)
        },
        'throwIfError',
      )
    } catch (error) {
      makeToast({
        type: 'error',
        autoHideDuration: 20000,
        heading: 'Error',
        message: `Error downloading file. Please try again`,
      })
      setFileActionsLoader(false)
    }
  }

  async function onDeleteFiles(fileForDownload: any) {
    try {
      await deleteFiles(
        constants.SCHEDULE_MILESTONE,
        scheduleMilestoneToEdit?.schedule_milestone_id,
        [fileForDownload.schedule_milestone_toss_object_key],
        null,
        'throwIfError',
      )

      getScheduleMilestonesForProject(projectId, (result: iSchedule) => {
        // Get the milestones again for the Project so as to refresh the cards in Milestones page
        if (result) {
          const scheduleMileStonesInProject =
            result?.schedule_milestones?.map?.((projectMilestones) => {
              return {
                ...projectMilestones,
                milestone_name: allMilestones?.find?.(
                  (allMilestone) =>
                    projectMilestones.milestone_id ===
                    allMilestone.milestone_id,
                )?.milestone_name,
              }
            })
          setProjectSchedules({
            ...result,
            schedule_milestones: scheduleMileStonesInProject,
          })
        }
        setFileActionsLoader(false)
      })
      makeToast({
        type: 'success',
        autoHideDuration: 20000,
        heading: 'File(s) Deleted!',
        message: `Deletion of ${fileForDownload.schedule_milestone_toss_file_name} was successful.`,
      })
    } catch (error) {
      makeToast({
        type: 'error',
        autoHideDuration: 20000,
        heading: 'Error',
        message: `Error deleting ${fileForDownload.schedule_milestone_toss_file_name} - ${error}. Please try again`,
      })
      setFileActionsLoader(false)
    }
  }

  function filesToUploadRender() {
    return (
      <Grid.Item
        className={`hc-mv-sm${validating ? ' centered-with-margin' : ''}`}
        xs={12}
      >
        {validating ? (
          <div className="hc-ta-center">
            <SkeletonCpm width="80vw" />
            <SkeletonCpm width="80vw" />
            <SkeletonCpm width="80vw" />
          </div>
        ) : scheduleMilestoneFiles?.length > 0 ? (
          <ExpandableSection padding="dense" expanded={true}>
            <Heading size={6}>
              {`Files to Upload (${scheduleMilestoneFiles?.length})`}
            </Heading>
            <ExpandableSection.Content>
              <List
                className="milestone-details-files-to-upload"
                size="expanded"
              >
                {scheduleMilestoneFiles?.map?.((currFile: any, idx: any) => {
                  return (
                    <List.Item key={idx}>
                      <div className="single-file-to-upload-row-container hc-ov-hidden">
                        <div className="file-upload-name-with-ellipsis">
                          {isValidImage(currFile) ? (
                            <div>
                              <Image
                                src={URL.createObjectURL(currFile.file)}
                                width="50px"
                                height="50px"
                                onClick={() => {
                                  setImageFullScreen(true)
                                  setClickedFile(currFile)
                                }}
                                alt={'image loading'}
                              />
                              <Input.Info className="file-upload-name-with-ellipsis-inner">
                                {currFile.name}
                              </Input.Info>
                            </div>
                          ) : (
                            <Anchor
                              href={URL.createObjectURL(currFile.file)}
                              target="_blank"
                              className="file-upload-name-with-ellipsis-inner"
                            >
                              {currFile.name}
                            </Anchor>
                          )}
                        </div>
                        <div className="single-file-to-upload-row-actions">
                          {fileRequired && actualEndDateChanged ? null : (
                            <Button
                              iconOnly
                              type="ghost"
                              aria-label="Click to edit file name"
                              onClick={() => {
                                setFileNameEditDialog(true)
                                setClickedFile({ file: currFile, index: idx })
                              }}
                            >
                              <EnterpriseIcon
                                icon={PencilIcon}
                                className="hc-clr-contrast"
                                size="md"
                              />
                            </Button>
                          )}
                          <Button
                            iconOnly
                            type="ghost"
                            aria-label="Click to remove file from upload list"
                            onClick={() => {
                              setScheduleMilestoneFiles(onTrashIconClick(idx))
                            }}
                          >
                            <EnterpriseIcon
                              icon={CancelIcon}
                              className="hc-clr-contrast"
                              size="md"
                            />
                          </Button>
                        </div>
                      </div>
                    </List.Item>
                  )
                })}
              </List>
            </ExpandableSection.Content>
          </ExpandableSection>
        ) : null}
      </Grid.Item>
    )
  }

  function uploadedFilesRender() {
    return (
      scheduleMilestoneToEdit?.schedule_milestone_toss_files?.length! > 0 && (
        <Grid.Item xs={12}>
          <ExpandableSection padding="dense" expanded={true}>
            <Heading size={6}>
              {`Uploaded Files (${scheduleMilestoneToEdit?.schedule_milestone_toss_files.length})`}
            </Heading>
            <ExpandableSection.Content>
              <List size="expanded">
                {scheduleMilestoneToEdit?.schedule_milestone_toss_files
                  .length! > 0
                  ? scheduleMilestoneToEdit?.schedule_milestone_toss_files?.map?.(
                      (currFile: any, idx: number) => {
                        const downloadLoading =
                          fileActionsLoader?.index === idx &&
                          fileActionsLoader?.action === 'download'
                        const deleteLoading =
                          fileActionsLoader?.index === idx &&
                          fileActionsLoader?.action === 'delete'
                        return (
                          <List.Item key={idx}>
                            <div className="single-file-to-upload-row-container hc-ov-hidden">
                              <div className="file-upload-name-with-ellipsis">
                                {currFile.schedule_milestone_toss_file_name}
                              </div>
                              <div className="single-file-to-upload-row-actions">
                                <Button
                                  iconOnly
                                  type="ghost"
                                  className={
                                    downloadLoading
                                      ? undefined
                                      : 'hc-clr-contrast'
                                  }
                                  isLoading={downloadLoading}
                                  aria-label="Click to download uploaded file"
                                  onClick={() => {
                                    setFileActionsLoader({
                                      index: idx,
                                      action: 'download',
                                    })
                                    onDownloadFiles(currFile)
                                  }}
                                >
                                  <EnterpriseIcon icon={DownloadIcon} />
                                </Button>
                                <Button
                                  iconOnly
                                  aria-label="Click to delete uploaded file"
                                  type="destructive"
                                  isLoading={deleteLoading}
                                  onClick={() => {
                                    setFileActionsLoader({
                                      index: idx,
                                      action: 'delete',
                                    })
                                    onDeleteFiles(currFile)
                                  }}
                                >
                                  <EnterpriseIcon icon={TrashIcon} />
                                </Button>
                              </div>
                            </div>
                          </List.Item>
                        )
                      },
                    )
                  : null}
              </List>
            </ExpandableSection.Content>
          </ExpandableSection>
        </Grid.Item>
      )
    )
  }

  function imageFullScreenRender() {
    function imageRender() {
      return (
        <Grid.Container>
          <Grid.Item xs={12} className="image-full-screen-header white-color">
            <Grid.Container align="center" className="hc-pa-sm">
              <Grid.Item xs={10} className="hc-fs-md ">
                {clickedFile.name}
              </Grid.Item>
              {!isDesktop && (
                <Grid.Item className="right-align-flex-container ">
                  <Button
                    iconOnly
                    type="secondary"
                    onClick={() => setImageFullScreen(false)}
                    aria-label="Close the Image preview"
                  >
                    <EnterpriseIcon
                      icon={CancelIcon}
                      className="hc-clr-contrast"
                      size="md"
                    />
                  </Button>
                </Grid.Item>
              )}
            </Grid.Container>
          </Grid.Item>
          <div>
            <img
              src={URL.createObjectURL(clickedFile.file)}
              className="center-fit"
              alt={''}
            />
          </div>
        </Grid.Container>
      )
    }
    return isDesktop ? (
      <Modal
        isVisible={imageFullScreen}
        size="expanded"
        onRefuse={() => setImageFullScreen(false)}
      >
        {imageRender()}
      </Modal>
    ) : (
      <FullScreen isVisible={imageFullScreen}>{imageRender()}</FullScreen>
    )
  }

  function fileNameEditDialogRender() {
    const selectedFile = clickedFile?.file
    const fileNameToBeEditedIndex = clickedFile?.index

    let selectedFileName = `${selectedFile.name.substr(
      0,
      selectedFile.name.lastIndexOf('.'),
    )}`
    let selectedFileExtension = `.${selectedFile.name.substr(
      selectedFile.name.lastIndexOf('.') + 1,
    )}`
    // Handle cases were file doesn't have extension - should not be needed as on demand scan will fail in that scenario
    if (selectedFileName === '') {
      selectedFileName = selectedFileExtension
      selectedFileExtension = ''
    }
    return (
      <Dialog
        className="file-name-edit-dialog"
        isVisible={fileNameEditDialog}
        onRefuse={() => setFileNameEditDialog(false)}
        onApprove={() => {
          const newFileName = `${newFileNameRef?.current?.value}${selectedFileExtension}`
          if (
            !newFileNameRef?.current?.value &&
            newFileNameRef?.current?.value !== '0'
          ) {
            makeToast({
              type: 'error',
              message: `File name cannot be empty`,
            })
            return
          }
          setScheduleMilestoneFiles(
            scheduleMilestoneFiles.map((currFile: any, idx: any) => ({
              ...currFile,
              name:
                idx === fileNameToBeEditedIndex ? newFileName : currFile.name,
            })),
          )
          setFileNameEditDialog(false)
        }}
        approveButtonText="Save"
        refuseButtonText="Cancel"
        as="div"
        //@ts-ignore
        bodyText={
          <span className="file-name-edit-dialog-div">
            <Input.Text
              ref={newFileNameRef}
              defaultValue={selectedFileName}
              className=".dialog-file-name-input"
              id={'file-name-edit-input'}
            />
            <span className="dialog-file-extension">
              {selectedFileExtension}
            </span>
          </span>
        }
      />
    )
  }

  return (
    <>
      {imageFullScreen && imageFullScreenRender()}
      {fileNameEditDialog && fileNameEditDialogRender()}
      <Divider />
      <Grid.Item className="hc-mt-md">
        <Input.DropArea
          id="test_id_00"
          data-testid="milestone-file-upload"
          onUpdate={(event: any) => {
            onUploadFiles(event)
          }}
          multiple
          instructionText="Select File to Upload"
          fullwidth
        />
      </Grid.Item>
      {filesToUploadRender()}
      {uploadedFilesRender()}
    </>
  )
}

export default FileUpload
