import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Autocomplete,
  AutocompleteOptions,
} from '@enterprise-ui/canvas-ui-react-autocomplete'
import {
  List,
  Card,
  Heading,
  Input,
  Grid,
  Popover,
  Button,
  Modal,
  Divider,
  Tooltip,
} from '@enterprise-ui/canvas-ui-react'
import { useAppContext } from '../../Context'
import { getUrlParam } from '../../utilities'
import constants from '../../utilities/constants'
import useServices from '../../services/useServices'
import {
  ProjectForLocationProps,
  iMilestone,
  iMilestoneAdditionalAttributes,
  iMilestoneDescriptionsArray,
  iSchedule,
  iScheduleMilestone,
  iScheduleTemplate,
} from '../../types/types'
import SkeletonCpm from '../../components/SkeletonCpm'
import { EnterpriseIcon, InfoFilledIcon } from '@enterprise-ui/icons'
import MilestoneColorCodeLegend from './MilestonesColorCodeLegend'
import {
  getMilestoneCardClassNames,
  getMilestoneStatus,
  sortMilestonesByDate,
} from './MilestoneUtilityFunctions'
const Milestones = () => {
  const {
    setHeading,
    setAllProgramTypes,
    allProgramTypes,
    setSelectedProgramName,
    setSelectedProgramType,
    selectedProgramName,
    selectedProgramType,
    setSelectedLocation,
    selectedLocation,
    setProjects,
    setAllProjectsForLocation,
    allScheduleViewTypes,
    setAllScheduleViewTypes,
    selectedScheduleViewType,
    setSelectedScheduleViewType,
    allMilestones,
    setAllMilestones,
    projectSchedules,
    setProjectSchedules,
    setSelectedMilestone,
    setScheduleMilestoneToEdit,
    reload,
    setReload,
    allScheduleTemplates,
    setAllScheduleTemplates,
    milestoneActualOwners,
    setMilestoneActualOwners,
    selectedMilestoneOwner,
    setSelectedMilestoneOwner,
  } = useAppContext()
  const {
    getProjectsForLocation,
    getProgramTypes,
    getSingleLocationDetails,
    getAllScheduleViewTypes,
    getAllMilestones,
    getScheduleMilestonesForProject,
    getAllScheduleTemplates,
    getAllMilestonesAdditionalAttributes,
  } = useServices()

  const locationId = getUrlParam(constants.LOCATION_PARAM)
  const projectId = getUrlParam(constants.PROJECT_PARAM)
  const programTypeId = getUrlParam(constants.PROGRAM_TYPE_PARAM)
  const [projectMilestonesToShow, setProjectMilestonesToShow] = useState<
    iScheduleMilestone[] | []
  >([])
  const [
    projectMilestonesToShowWithOwners,
    setProjectMilestonesToShowWithOwners,
  ] = useState<iScheduleMilestone[] | []>([])
  const [searchMilestonesText, setSearchMilestonesText] = useState<string>('')
  const navigate = useNavigate()
  const [isMilestoneDescriptionModalOpen, setIsMilestoneDescriptionModalOpen] =
    useState<boolean>(false)
  const [searchMilestoneDescriptionsText, setSearchMilestoneDescriptionsText] =
    useState<string>('')
  const [milestoneDescriptionsArray, setMilestoneDescriptionsArray] = useState<
    iMilestoneDescriptionsArray[]
  >([])
  const [
    initialMilestoneAdditionalAttributesArray,
    setInitialMilestoneAdditionalAttributesArray,
  ] = useState<iMilestoneAdditionalAttributes[]>([])

  useEffect(() => {
    setHeading('Select Milestones')
    window.scrollTo(0, 0)
    // *** Call API's for milestone****
    if (!allScheduleViewTypes) {
      getAllScheduleViewTypes((result: any) => setAllScheduleViewTypes(result))
    }
    // Call All milestones API and call project ,milestones api in the callback so as to set the milestone_name property
    //If allMilestones already exists in context, no need to call allMilestones API
    function milestonesForPrjctFn(allMilestonesFromApi?: iMilestone[]) {
      getScheduleMilestonesForProject(projectId, (result: iSchedule) => {
        // Add Milestone name property to the schedule milestone array
        if (result) {
          setProjectSchedules({
            ...result,
            schedule_milestones: result?.schedule_milestones?.map?.(
              (projectMilestones) => {
                return {
                  ...projectMilestones,
                  milestone_name: (
                    allMilestonesFromApi || allMilestones
                  )?.find?.(
                    (allMilestone) =>
                      projectMilestones.milestone_id ===
                      allMilestone.milestone_id,
                  )?.milestone_name,
                }
              },
            ),
          })
        } else {
          setProjectSchedules({}) // set to empty object so that the no schedules found doesnt show unnecessarily
        }
        setReload(false) // set reload to false. used to handle skeleton loader
      })
    }

    if (!allMilestones) {
      getAllMilestones((allMilestones: iMilestone[]) => {
        setAllMilestones(allMilestones)
        if (projectId !== projectSchedules?.project_id?.toString()) {
          milestonesForPrjctFn(allMilestones)
        }
      })
    } else {
      if (projectId !== projectSchedules?.project_id?.toString() || reload) {
        milestonesForPrjctFn()
      }
    }

    //** End API calls for Milestone page  */

    // call API to get the location name to show in the header bar - this is required during reload
    if (!selectedLocation) {
      getSingleLocationDetails(locationId, (result: { location_name: any }) =>
        setSelectedLocation(`${locationId} ${result?.location_name}`),
      )
    }

    ;(async function () {
      // call program types only if not in context . No need to call when redirecting to the page all the time, unless its a reload
      let allProgramTypeResult = allProgramTypes || null
      if (!allProgramTypes) {
        allProgramTypeResult = await getProgramTypes()
        setAllProgramTypes(allProgramTypeResult)
      }
      // call schedule templates only if not in context. No need to call when redirecting to the page all the time, unless it's a reload
      if (!allScheduleTemplates) {
        getAllScheduleTemplates((allScheduleTemplates: iScheduleTemplate[]) => {
          setAllScheduleTemplates(allScheduleTemplates)
        })
      }
      // call API's to get the selected program name and selected program type - needs to be called after getting all program types above
      if (!selectedProgramType || !selectedProgramName) {
        getProjectsForLocation(locationId, (result: any[]) => {
          setAllProjectsForLocation(result)
          const selectedProgramType = allProgramTypeResult?.find(
            (programType) => programType?.value === parseInt(programTypeId),
          )

          const selectedProgramName =
            result?.find(
              (project: { project_id: number }) =>
                project?.project_id === parseInt(projectId),
            )?.program_name ?? ''

          setSelectedProgramName(selectedProgramName)
          setSelectedProgramType(selectedProgramType)
          setProjects(
            result?.filter?.(
              (val: ProjectForLocationProps) =>
                val.program_type_id === selectedProgramType?.value,
            ) ?? [],
          )
        })
      }
    })()
  }, [])

  // This function is for showing only the active schedule view types for the Project
  function getActiveScheduleViewTypes() {
    const activeScheduleViewTypes =
      allMilestones
        ?.slice()
        ?.filter((milestones: iMilestone) => {
          return projectSchedules?.schedule_milestones?.find(
            (projectMilestones: iScheduleMilestone) =>
              milestones.milestone_id === projectMilestones.milestone_id,
          )
        })
        ?.map((milestones: iMilestone) => milestones.schedule_view_type_ids)
        ?.flat?.()
        ?.filter?.((x, i, a) => a.indexOf(x) === i) ?? []

    return (
      allScheduleViewTypes?.filter?.((scheduleViewType: AutocompleteOptions) =>
        activeScheduleViewTypes?.find?.(
          (filteredScheduleViewTypeIds) =>
            scheduleViewType.value === filteredScheduleViewTypeIds,
        ),
      ) ?? null
    )
  }

  // This function is for showing only the active schedule view types for the Project
  function getMilestonesForProject(selectedSchViewType: AutocompleteOptions) {
    setProjectMilestonesToShow(
      projectSchedules?.schedule_milestones?.filter(
        (scheduleMilestone: iScheduleMilestone) => {
          return selectedSchViewType === null
            ? false
            : allMilestones
                ?.find?.(
                  (allMilestone) =>
                    scheduleMilestone.milestone_id ===
                    allMilestone.milestone_id,
                )
                ?.schedule_view_type_ids?.includes(selectedSchViewType?.value)
        },
      ) ?? [],
    )
  }

  function getMilestonesForProjectWithSelectedMilestoneOwner(
    selectedOwner: AutocompleteOptions,
  ) {
    let matchingMilestoneOwnersArray: any = []

    allScheduleTemplates?.map((scheduleTemplate) => {
      // map over all of the schedule templates to find the matching selected template from the program type id
      if (scheduleTemplate?.program_type_id === selectedProgramType?.id) {
        // map over the matched schedule template milestones to find matching selected owner and push to an array
        return scheduleTemplate?.schedule_template_milestones.map(
          (milestone) => {
            // if the selected owner is Unassigned then only return null milestone owners otherwise find the matching owner
            if (selectedOwner?.label === 'Unassigned') {
              if (milestone?.schedule_template_milestone_owner === null) {
                return matchingMilestoneOwnersArray.push(milestone)
              } else return null
            } else {
              if (milestone?.schedule_template_milestone_owner !== null) {
                if (
                  milestone?.schedule_template_milestone_owner?.owner_actual ===
                  selectedOwner?.value
                ) {
                  return matchingMilestoneOwnersArray.push(milestone)
                } else return null
              } else return null
            }
          },
        )
      } else return null
    })

    // Finds the matching milestones from the projectMilestonesToShow array and the matchingMilestoneOwners array
    // and set them to the projectMilestonesToShowWithOwners array for display
    const combinedArray = projectMilestonesToShow.filter((projectMilestone) =>
      matchingMilestoneOwnersArray.some(
        (matchingMilestone: { milestone_id: number }) =>
          matchingMilestone.milestone_id === projectMilestone.milestone_id,
      ),
    )
    setProjectMilestonesToShowWithOwners(combinedArray)
  }

  useEffect(() => {
    if (selectedScheduleViewType?.value) {
      getMilestonesForProject(selectedScheduleViewType)
    }
  }, [projectSchedules, selectedScheduleViewType])

  useEffect(() => {
    if (allScheduleTemplates && selectedProgramType) {
      setMilestoneActualOwners(
        [
          ...new Set(
            allScheduleTemplates
              ?.map((scheduleTemplate, index) => {
                return filterUniqueActualOwners(scheduleTemplate)
              })
              ?.filter((val) => val !== null)?.[0],
          ),
        ]
          .map((ownerString, i) => ({
            id: i,
            label: ownerString,
            value: ownerString,
          }))
          .sort(),
      )
    }
  }, [allScheduleTemplates, selectedProgramType])

  useEffect(() => {
    setSelectedMilestoneOwner(null)
    setProjectMilestonesToShowWithOwners([])
  }, [selectedScheduleViewType])

  useEffect(() => {
    getAllMilestonesAdditionalAttributes(
      (milestoneAttribs: iMilestoneAdditionalAttributes[]) => {
        setInitialMilestoneAdditionalAttributesArray(milestoneAttribs)
      },
    )

    if (!selectedMilestoneOwner) {
      const fullMilestoneObjWithNullDescription = projectMilestonesToShow?.map(
        (milestone) => {
          return {
            milestone_id: milestone.milestone_id,
            milestone_name: allMilestones?.find(
              (allMilestone: any) =>
                milestone.milestone_id === allMilestone.milestone_id,
            )?.milestone_name,
            milestone_description:
              initialMilestoneAdditionalAttributesArray?.find(
                (milestoneAtt: any) =>
                  milestone.milestone_id === milestoneAtt.milestone_id,
              )?.milestone_description,
          }
        },
      )

      const filterMilestoneObjWithoutNullDescriptions =
        fullMilestoneObjWithNullDescription.filter((milestoneObj) => {
          return (
            milestoneObj.milestone_description !== undefined &&
            milestoneObj.milestone_description !== null &&
            milestoneObj.milestone_description !== ''
          )
        })

      setMilestoneDescriptionsArray(filterMilestoneObjWithoutNullDescriptions)
    } else {
      const fullMilestoneObjWithNullDescriptionOwners =
        projectMilestonesToShowWithOwners?.map((milestone) => {
          return {
            milestone_id: milestone.milestone_id,
            milestone_name: allMilestones?.find(
              (allMilestone: any) =>
                milestone.milestone_id === allMilestone.milestone_id,
            )?.milestone_name,
            milestone_description:
              initialMilestoneAdditionalAttributesArray?.find(
                (milestoneAtt: any) =>
                  milestone.milestone_id === milestoneAtt.milestone_id,
              )?.milestone_description,
          }
        })

      const filterMilestoneObjWithoutNullDescriptionsOwners =
        fullMilestoneObjWithNullDescriptionOwners.filter((milestoneObj) => {
          return (
            milestoneObj.milestone_description !== undefined &&
            milestoneObj.milestone_description !== null &&
            milestoneObj.milestone_description !== ''
          )
        })

      setMilestoneDescriptionsArray(
        filterMilestoneObjWithoutNullDescriptionsOwners,
      )
    }
  }, [projectMilestonesToShow.length, projectMilestonesToShowWithOwners.length])

  const CardItem = ({ label, content }: { label: string; content: any }) => (
    <Grid.Item xs={6}>
      {reload ? (
        <SkeletonCpm width="20vw" />
      ) : (
        <>
          <Input.Label className="hc-fs-md hc-ws-no-wrap">{label}</Input.Label>
          <div className="hc-fs-md card-content-div hc-ws-no-wrap">
            <strong>{content ?? 'N/A'} </strong>
          </div>
        </>
      )}
    </Grid.Item>
  )

  function filterUniqueActualOwners(scheduleTemp: iScheduleTemplate) {
    let uniqueMilestoneActualOwners: string[] = []

    if (scheduleTemp.program_type_id === selectedProgramType?.id) {
      // maps over the scheduleTemp and only grab the actual owners value and add to the uniqueMilestoneActualOwners set
      scheduleTemp?.schedule_template_milestones?.map((milestone) => {
        if (milestone.schedule_template_milestone_owner !== null) {
          uniqueMilestoneActualOwners.push(
            milestone?.schedule_template_milestone_owner?.['owner_actual'] ??
              'Unassigned',
          )
        }
        return null
      })
      return uniqueMilestoneActualOwners
    } else {
      return null
    }
  }

  return (
    <>
      {allScheduleViewTypes && projectSchedules ? (
        (getActiveScheduleViewTypes?.() ?? []).length > 0 ? (
          <>
            <Autocomplete
              className="hc-mb-md"
              id="scheduleViewTypes"
              data-testid="scheduleViewTypesTestId"
              required
              options={() => getActiveScheduleViewTypes()}
              value={selectedScheduleViewType}
              label={'Select Schedule View Type'}
              onUpdate={(id, value) => {
                setSelectedScheduleViewType(value)
                getMilestonesForProject(value)
              }}
              disableFieldInfo
            />
            {selectedScheduleViewType && (
              <Autocomplete
                className="hc-mb-md"
                id="scheduleMilestoneOwners"
                options={milestoneActualOwners ? milestoneActualOwners : []}
                label={'Filter by Actual Date Owners'}
                value={selectedMilestoneOwner}
                onUpdate={(id, value) => {
                  setSelectedMilestoneOwner(value)
                  getMilestonesForProjectWithSelectedMilestoneOwner(value)
                }}
                disableFieldInfo
              />
            )}
          </>
        ) : (
          <div className="hc-pa-md hc-ta-center hc-fs-lg">
            No Schedules Found
          </div>
        )
      ) : (
        <SkeletonCpm highlightColor="white" height={30} />
      )}

      {(
        selectedMilestoneOwner
          ? projectMilestonesToShowWithOwners?.length > 0
          : projectMilestonesToShow?.length > 0
      ) ? (
        <Card className="hc-bg-grey07 hc-pa-md cpm-milestone-item-card cpm-milestone-pages-card">
          {milestoneDescriptionsArray.length === 0 ? (
            <Tooltip
              content="No Milestone Descriptions Available"
              location="top-right"
            >
              <Button className="hc-mb-sm" disabled>
                View Milestone Definitions
              </Button>
            </Tooltip>
          ) : (
            <Button
              className="hc-mb-sm"
              onClick={() => setIsMilestoneDescriptionModalOpen(true)}
              disabled={milestoneDescriptionsArray.length === 0}
            >
              View Milestone Definitions
            </Button>
          )}

          <Input.Label>
            <div className="flex">
              Search Milestones
              <Popover
                type="clickOnly"
                content={<MilestoneColorCodeLegend />}
                location="bottom"
              >
                <EnterpriseIcon
                  size="sm"
                  icon={InfoFilledIcon}
                  className="clickable"
                />
              </Popover>
            </div>
          </Input.Label>
          <Input.Text
            id="search-milestones"
            data-testid="search-milestones"
            onChange={(e: {
              target: { value: React.SetStateAction<string> }
            }) => setSearchMilestonesText(e.target.value)}
          ></Input.Text>
          <List className="hc-pt-sm">
            {sortMilestonesByDate(
              !selectedMilestoneOwner
                ? projectMilestonesToShow
                : projectMilestonesToShowWithOwners,
            )
              ?.filter((milestone: iScheduleMilestone) =>
                milestone?.milestone_name
                  ?.toLowerCase()
                  ?.includes?.(searchMilestonesText?.toLowerCase()),
              )
              ?.map((milestone: iScheduleMilestone) => (
                <List.Item key={milestone.schedule_milestone_id}>
                  <Card
                    className={getMilestoneCardClassNames(milestone)}
                    onClick={() => {
                      navigate({
                        pathname: `${constants.MILESTONES_PATH}/${milestone.milestone_id}`,
                        search: new URLSearchParams({
                          [constants.LOCATION_PARAM]: locationId,
                          [constants.PROJECT_PARAM]: projectId,
                          [constants.PROGRAM_TYPE_PARAM]: programTypeId,
                          [constants.SCHEDULE_VIEW_TYPE_PARAM]:
                            selectedScheduleViewType?.value,
                        }).toString(),
                      })
                      setSelectedMilestone({
                        id: milestone.milestone_id,
                        name: milestone.milestone_name,
                      })
                      setScheduleMilestoneToEdit(milestone)
                    }}
                  >
                    <Heading size={4}>{milestone.milestone_name}</Heading>
                    {milestone.planned_start_date &&
                    milestone.planned_end_date ? (
                      <div className="milestone-status">
                        <span>{getMilestoneStatus(milestone)}</span>
                      </div>
                    ) : null}

                    <Grid.Container className="hc-pa-md">
                      <CardItem
                        label="Planned Start"
                        content={milestone.planned_start_date}
                      />
                      <CardItem
                        label="Planned End"
                        content={milestone.planned_end_date}
                      />
                      <CardItem
                        label="Forecast Start"
                        content={milestone.forecasted_start_date}
                      />
                      <CardItem
                        label="Forecast End"
                        content={milestone.forecasted_end_date}
                      />
                      <CardItem
                        label="Actual Start"
                        content={milestone.actual_start_date}
                      />
                      <CardItem
                        label="Actual End"
                        content={milestone.actual_end_date}
                      />
                    </Grid.Container>
                  </Card>
                </List.Item>
              ))}
          </List>
          <Modal
            headingText="Milestone Definitions"
            isVisible={isMilestoneDescriptionModalOpen}
            onRefuse={() => setIsMilestoneDescriptionModalOpen(false)}
          >
            <div className="hc-pa-sm">
              <Input.Label>Search Milestones</Input.Label>
              <Input.Text
                id="search-milestone-descriptions"
                className="hc-mb-sm"
                onChange={(e: {
                  target: { value: React.SetStateAction<string> }
                }) => setSearchMilestoneDescriptionsText(e.target.value)}
              ></Input.Text>
              <Divider className="hc-mb-sm" />
              {milestoneDescriptionsArray
                .filter((milestoneObj: iMilestoneDescriptionsArray) =>
                  milestoneObj?.milestone_name
                    ?.toLowerCase()
                    ?.includes?.(
                      searchMilestoneDescriptionsText?.toLowerCase(),
                    ),
                )
                .map(
                  (milestone: iMilestoneDescriptionsArray, index: number) => (
                    <div id={index.toString()}>
                      <strong>{milestone.milestone_name}: </strong>
                      {milestone.milestone_description}
                      <Divider className="hc-mt-sm hc-mb-sm" />
                    </div>
                  ),
                )}
            </div>
          </Modal>
        </Card>
      ) : projectMilestonesToShow?.length > 0 ||
        projectMilestonesToShowWithOwners?.length > 0 ? (
        <div className="hc-pa-md hc-ta-center hc-fs-lg">
          No Milestones Found
        </div>
      ) : null}
    </>
  )
}

export default Milestones
