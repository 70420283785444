import { useEffect } from 'react'
import { useAuth } from '@praxis/component-auth'
import { useNavigate, useLocation } from 'react-router-dom'
import {
  Layout,
  Avatar,
  Button,
  Dropdown,
  Grid,
  Heading,
  ToastProvider,
  List,
  Input,
} from '@enterprise-ui/canvas-ui-react'
import Cookies from 'js-cookie'
import { MainRouter } from '../routes/MainRouter'

import { useAppContext } from '../Context'
import constants from '../utilities/constants'
import { isPOLSessionValid } from '../utilities'

const getInitials = (userInfo: any) =>
  `${userInfo.firstName.charAt(0)}${userInfo.lastName.charAt(0)}`

export const Main: React.FC = () => {
  const auth = useAuth()
  const { heading, isDesktop, darkMode, setDarkMode, isExternal } =
    useAppContext()
  const navigate = useNavigate()
  const location = useLocation()
  const makeToast = ToastProvider.useToaster()

  // POL SESSION VALIDATION
  useEffect(() => {
    isExternal &&
      setInterval(() => {
        if (!isPOLSessionValid(auth?.session?.userInfo?.lanId || '', Cookies)) {
          localStorage.removeItem('id_token')
          localStorage.removeItem('access_token')

          console.log('Invalid POL session. removing id_token and access_token')

          makeToast({
            type: 'alert',
            heading: 'POL Session!',
            message:
              'Invalid session or Company change found. Redirecting to login/home page...',
          })

          window.location.href = '/'
        }
      }, 30000)
  }, [])

  // const firstRoute = window?.location?.pathname === constants.LOCATION_PATH
  // redirect to /locations on first load
  useEffect(() => {
    if (
      auth.isAuthenticated() &&
      (location?.pathname === constants.MOBILE_BASE_PATH ||
        location?.pathname === '/')
    ) {
      navigate(constants.LOCATION_PATH)
    }
  }, [location])

  useEffect(() => {
    window?.localStorage?.setItem?.('darkMode', darkMode)
  }, [darkMode])

  const lightDarkToggle = () => (
    <div className="hc-pb-xs">
      <label className="darkModeToggleLabel">Dark Mode</label>
      <Input.Toggle
        aria-label="Toggle to switch between light and dark modes"
        id="dark-mode-enable"
        checked={darkMode === 'true'}
        onChange={() => setDarkMode(darkMode === 'true' ? 'false' : 'true')}
      />
    </div>
  )
  if (auth.isAuthenticated()) {
    return (
      <Layout
        theme="default" // configure theme here
        darkMode={darkMode} // configure dark mode here
        data-testid="mainAuthenticatedLayout"
        fullWidth={true}
        className={isDesktop ? 'cpm-mobile-desktop' : undefined}
      >
        <Layout.Header fixed className="hc-fs-xl">
          <Heading
            size={4}
            className="headerTitle centered-flex"
            onClick={() => navigate(constants.LOCATION_PATH)}
          >
            <List className="flex">
              <List.Item className="hc-clr-contrast-weak">
                <div className="hc-clr-contrast-weak">
                  <div className="white-color hc-fs-lg">
                    {constants.APP_HEADER}
                  </div>
                  <div className="white-color hc-fs-sm">{heading}</div>
                </div>
              </List.Item>
            </List>
          </Heading>
        </Layout.Header>
        <Layout.GlobalActions className="hc-pt-lg">
          <Grid.Container justify="flex-end" align="center">
            <Grid.Item>{lightDarkToggle()}</Grid.Item>
            <Grid.Item>
              <Dropdown location="bottom-right">
                <Button
                  aria-label="Click to open dropdown menu"
                  iconOnly
                  className="praxcss-avatarButton"
                  data-testid="headerGlobalActionsButton"
                >
                  <Avatar
                    aria-label={`Avatar showing the initials for ${auth?.session?.userInfo?.fullName}`}
                    color="teal"
                  >
                    {getInitials(auth?.session?.userInfo)}
                  </Avatar>
                </Button>
                <Dropdown.Menu>
                  {isExternal && (
                    <Dropdown.MenuItem disabled>
                      {auth?.session?.userInfo?.company ?? ''}
                    </Dropdown.MenuItem>
                  )}
                  <Dropdown.MenuItem
                    data-testid="headerLogoutButton"
                    onClick={auth.logout}
                  >
                    Log Out
                  </Dropdown.MenuItem>
                </Dropdown.Menu>
              </Dropdown>
            </Grid.Item>
          </Grid.Container>
        </Layout.GlobalActions>
        <Layout.Body
          data-testid="capital-projects-mobile"
          includeRail
          className="cpm-mobile-root"
        >
          <div
            className={
              isDesktop ? 'cpm-desktop-root-inner' : 'cpm-mobile-root-inner'
            }
          >
            <ToastProvider location="bottom">
              <MainRouter />
            </ToastProvider>
          </div>
        </Layout.Body>
      </Layout>
    )
  } else {
    auth.login()
    return null
  }
}
