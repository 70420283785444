const constants = {
  PDF_INVOICE_DATA: {
    tcpm_invoice_id: '1',
    vendor_name: 'STEWART PERRY',
    target_vendor_number: '0000053806',
    vendor_contact_email: 'tmiller@stewartperry.com',
    vendor_address_line1: null,
    city: null,
    state: null,
    zip: null,
    vendor_contact_phone: '12057906955',
    status: 'Completed',
    sap_po_number: '5000091541',
    sap_vendor_invoice_number: '1-3362',
    tcpm_project_number: 'PDS1472575',
    sap_project_number: '21000323',
    sap_project_name: 'Athens Broad Street, GA T-3362',
    contract_number: '1000111516',
    invoice_amount: 604839.7,
    invoice_line_details: [
      {
        invoice_line_number: 10,
        cost_code_description: 'CONTRACTORS FEE',
        contract_line_item_amount: 102606.17,
        previous_invoice_amount: 0.0,
        current_invoice_amount: 25000.0,
        current_invoice_retention_amount: 1250.0,
      },
      {
        invoice_line_number: 20,
        cost_code_description: 'BUILDING PERMIT',
        contract_line_item_amount: 8005.37,
        previous_invoice_amount: 0.0,
        current_invoice_amount: 8005.37,
        current_invoice_retention_amount: 400.2685,
      },
      {
        invoice_line_number: 30,
        cost_code_description: 'CONTRACT GENERAL CONDITIONS',
        contract_line_item_amount: 427854.4,
        previous_invoice_amount: 0.0,
        current_invoice_amount: 95000.0,
        current_invoice_retention_amount: 4750.0,
      },
      {
        invoice_line_number: 40,
        cost_code_description: 'TEMPORARY FACILITIES',
        contract_line_item_amount: 59245.0,
        previous_invoice_amount: 0.0,
        current_invoice_amount: 15555.0,
        current_invoice_retention_amount: 777.75,
      },
      {
        invoice_line_number: 50,
        cost_code_description: 'INTERIOR DEMOLITION',
        contract_line_item_amount: 25500.0,
        previous_invoice_amount: 0.0,
        current_invoice_amount: 21200.0,
        current_invoice_retention_amount: 1060.0,
      },
      {
        invoice_line_number: 60,
        cost_code_description: 'BUILDING CONCRETE',
        contract_line_item_amount: 57620.0,
        previous_invoice_amount: 0.0,
        current_invoice_amount: 57620.0,
        current_invoice_retention_amount: 2881.0,
      },
      {
        invoice_line_number: 70,
        cost_code_description: 'ROUGH CARPENTRY',
        contract_line_item_amount: 39990.0,
        previous_invoice_amount: 0.0,
        current_invoice_amount: 11877.0,
        current_invoice_retention_amount: 593.85,
      },
      {
        invoice_line_number: 80,
        cost_code_description: 'DOORS FRAMES HARDWARE',
        contract_line_item_amount: 20410.0,
        previous_invoice_amount: 0.0,
        current_invoice_amount: 15636.0,
        current_invoice_retention_amount: 781.8,
      },
      {
        invoice_line_number: 90,
        cost_code_description: 'ENTRANCES STOREFRONTS',
        contract_line_item_amount: 14275.0,
        previous_invoice_amount: 0.0,
        current_invoice_amount: 0.0,
        current_invoice_retention_amount: 0.0,
      },
      {
        invoice_line_number: 100,
        cost_code_description: 'DRYWALL INSTALL',
        contract_line_item_amount: 148500.0,
        previous_invoice_amount: 0.0,
        current_invoice_amount: 67780.0,
        current_invoice_retention_amount: 3389.0,
      },
      {
        invoice_line_number: 110,
        cost_code_description: 'RESILIENT FLOORING INSTALL',
        contract_line_item_amount: 61493.0,
        previous_invoice_amount: 0.0,
        current_invoice_amount: 0.0,
        current_invoice_retention_amount: 0.0,
      },
      {
        invoice_line_number: 120,
        cost_code_description: 'PAINTING',
        contract_line_item_amount: 38195.0,
        previous_invoice_amount: 0.0,
        current_invoice_amount: 0.0,
        current_invoice_retention_amount: 0.0,
      },
      {
        invoice_line_number: 130,
        cost_code_description: 'MISC SPECIALTIES',
        contract_line_item_amount: 8700.0,
        previous_invoice_amount: 0.0,
        current_invoice_amount: 2700.0,
        current_invoice_retention_amount: 135.0,
      },
      {
        invoice_line_number: 140,
        cost_code_description: 'TOILET PARTITIONS',
        contract_line_item_amount: 500.0,
        previous_invoice_amount: 0.0,
        current_invoice_amount: 0.0,
        current_invoice_retention_amount: 0.0,
      },
      {
        invoice_line_number: 150,
        cost_code_description: 'FIRE PROTECT SYSTEM',
        contract_line_item_amount: 5110.0,
        previous_invoice_amount: 0.0,
        current_invoice_amount: 2860.0,
        current_invoice_retention_amount: 143.0,
      },
      {
        invoice_line_number: 160,
        cost_code_description: 'BASIC BLDG PLUMBING',
        contract_line_item_amount: 144725.0,
        previous_invoice_amount: 0.0,
        current_invoice_amount: 29000.0,
        current_invoice_retention_amount: 1450.0,
      },
      {
        invoice_line_number: 170,
        cost_code_description: 'REFRIGERATION INSTALLATION',
        contract_line_item_amount: 228444.0,
        previous_invoice_amount: 0.0,
        current_invoice_amount: 65000.0,
        current_invoice_retention_amount: 3250.0,
      },
      {
        invoice_line_number: 180,
        cost_code_description: 'HVAC INSTALLATION',
        contract_line_item_amount: 92000.0,
        previous_invoice_amount: 0.0,
        current_invoice_amount: 54440.0,
        current_invoice_retention_amount: 2722.0,
      },
      {
        invoice_line_number: 190,
        cost_code_description: 'ELECTRICAL SYSTEMS',
        contract_line_item_amount: 485000.0,
        previous_invoice_amount: 0.0,
        current_invoice_amount: 165000.0,
        current_invoice_retention_amount: 8250.0,
      },
    ],
  },
  LOCATIONS: [
    {
      location_id: '3426',
      type_code: 'STR',
      type_description: 'Store',
      sub_type_code: 'Small Format',
      status: 'Canceled',
      location_name:
        'LA East Hollywood - Cancelled. Adding some notes to see how this looks when the text is wrapped',
      city: 'Los Angeles',
      state: 'California',
    },
    {
      location_id: '3448',
      type_code: 'STR',
      type_description: 'Store',
      sub_type_code: 'Small Format',
      status: 'Canceled',
      location_name: 'Seattle Columbia City - Canceled',
      city: 'Seattle',
      state: 'Washington',
    },
    {
      location_id: '3370',
      type_code: 'STR',
      type_description: 'Store',
      sub_type_code: 'Small Format',
      status: 'Canceled',
      location_name:
        'Queens Middle Village-Cancelled.Adding some notes to see how this looks when the text is wrapped',
      city: 'Middle Village',
      state: 'New York',
    },
    {
      location_id: '7',
      type_code: 'STR',
      type_description: 'Store',
      sub_type_code: 'General Merch',
      status: 'Closed',
      location_name: 'Westland',
      city: 'Lakewood',
      state: 'Colorado',
    },
    {
      location_id: '22',
      type_code: 'STR',
      type_description: 'Store',
      sub_type_code: 'General Merch',
      status: 'Closed',
      location_name: 'University City',
      city: 'University City',
      state: 'Missouri',
    },
    {
      location_id: '44',
      type_code: 'STR',
      type_description: 'Store',
      sub_type_code: 'General Merch',
      status: 'Closed',
      location_name: 'Oklahoma City South',
      city: 'Oklahoma City',
      state: 'Oklahoma',
    },
    {
      location_id: '54',
      type_code: 'STR',
      type_description: 'Store',
      sub_type_code: 'General Merch',
      status: 'Closed',
      location_name: 'Maplewood',
      city: 'Omaha',
      state: 'Nebraska',
    },
    {
      location_id: '59',
      type_code: 'STR',
      type_description: 'Store',
      sub_type_code: 'General Merch',
      status: 'Closed',
      location_name: 'Southwest Omaha',
      city: 'Omaha',
      state: 'Nebraska',
    },
    {
      location_id: '92',
      type_code: 'STR',
      type_description: 'Store',
      sub_type_code: 'General Merch',
      status: 'Closed',
      location_name: 'Wichita East',
      city: 'Wichita',
      state: 'Kansas',
    },
    {
      location_id: '89',
      type_code: 'STR',
      type_description: 'Store',
      sub_type_code: 'General Merch',
      status: 'Closed',
      location_name: 'Memphis North',
      city: 'Memphis',
      state: 'Tennessee',
    },
    {
      location_id: '131',
      type_code: 'STR',
      type_description: 'Store',
      sub_type_code: 'General Merch',
      status: 'Closed',
      location_name: 'Shelbyville',
      city: 'Shelbyville',
      state: 'Indiana',
    },
    {
      location_id: '157',
      type_code: 'STR',
      type_description: 'Store',
      sub_type_code: 'General Merch',
      status: 'Closed',
      location_name: 'Baybrook',
      city: 'Webster',
      state: 'Texas',
    },
    {
      location_id: '168',
      type_code: 'STR',
      type_description: 'Store',
      sub_type_code: 'General Merch',
      status: 'Closed',
      location_name:
        'Garland North.Adding some notes to see how this looks when the text is wrapped',
      city: 'Garland',
      state: 'Texas',
    },
    {
      location_id: '174',
      type_code: 'STR',
      type_description: 'Store',
      sub_type_code: 'General Merch',
      status: 'Closed',
      location_name: 'Marbach',
      city: 'San Antonio',
      state: 'Texas',
    },
    {
      location_id: '204',
      type_code: 'STR',
      type_description: 'Store',
      sub_type_code: 'General Merch',
      status: 'Closed',
      location_name: 'Chula Vista North 4th',
      city: 'Chula Vista',
      state: 'California',
    },
    {
      location_id: '102',
      type_code: 'STR',
      type_description: 'Store',
      sub_type_code: 'General Merch',
      status: 'Closed',
      location_name: 'Elkhart',
      city: 'Elkhart',
      state: 'Indiana',
    },
    {
      location_id: '308',
      type_code: 'STR',
      type_description: 'Store',
      sub_type_code: 'General Merch',
      status: 'Closed',
      location_name: 'Indio',
      city: 'Indio',
      state: 'California',
    },
    {
      location_id: '326',
      type_code: 'STR',
      type_description: 'Store',
      sub_type_code: 'General Merch',
      status: 'Closed',
      location_name: 'Fremont',
      city: 'Fremont',
      state: 'California',
    },
    {
      location_id: '371',
      type_code: 'STR',
      type_description: 'Store',
      sub_type_code: 'General Merch',
      status: 'Closed',
      location_name: 'Sandy Springs',
      city: 'Atlanta',
      state: 'Georgia',
    },
  ],
  PROJECTS: [
    {
      project_id: 1231313,
      program_type: 'Remodel',
      project_name:
        '2023 Remodels . Big Description text for projects to check wrapping',
    },
    {
      project_id: 23423134,
      program_type: 'Special Project',
      project_name: 'Test Special 1',
    },
    {
      project_id: 242313,
      program_type: 'Remodel',
      project_name:
        'Test Remodels 2 . Big Description text for projects to check wrapping',
    },
    {
      project_id: 333524,
      program_type: 'Special Project',
      project_name: 'Test Special 2',
    },
    {
      project_id: 541313,
      program_type: 'New Store',
      project_name: 'Test New store 1',
    },
    {
      project_id: 352333,
      program_type: 'GSCL Remodel',
      project_name: 'Test GSCL new 1',
    },
    {
      project_id: 4534231,
      program_type: 'Property Management',
      project_name: 'Test Prop Management 1',
    },
    {
      project_id: 242432424,
      program_type: 'Remodel',
      project_name: 'Test Remodel 999',
    },
    {
      project_id: 344431,
      program_type: 'Special Project',
      project_name: 'Test Special Project 20',
    },
    {
      project_id: 54534323,
      program_type: 'New Store',
      project_name: 'New store Minneapolis',
    },
    {
      project_id: 1213213,
      program_type: 'Property Management',
      project_name: 'Test PM Skylight',
    },
    {
      project_id: 654424,
      program_type: 'Remodel',
      project_name: 'Test Remodel 1000',
    },
    {
      project_id: 7764322,
      program_type: 'GSCL Remodel',
      project_name: '2023 Remodels',
    },
    {
      project_id: 77332311,
      program_type: 'GSCL New Location',
      project_name: 'Test GSCL New 123',
    },
    {
      project_id: 2425325,
      program_type: 'Special Project',
      project_name: 'Test Special Skylight Mobile',
    },
  ],
  MILESTONE_TYPES: [
    { id: 1, label: 'All Milestones', value: 'All Milestones' },
    { id: 2, label: 'Sequence Milestones', value: 'Sequence Milestones' },
    {
      id: 3,
      label: 'Store Design Milestones',
      value: 'Store Design Milestones',
    },
    {
      id: 4,
      label: 'Construction Milestones',
      value: 'Construction Milestones',
    },
    { id: 5, label: 'A&E Milestones', value: 'A&E Milestones' },
  ],
  MILESTONES: [
    {
      schedule_milestone_id: 1474888,
      schedule_id: 98579,
      milestone_id: 4,
      model_date: null,
      planned_start_date: '2023-07-26',
      planned_end_date: null,
      forecasted_start_date: null,
      forecasted_end_date: null,
      actual_start_date: null,
      actual_end_date: null,
      critical_path_start_date: null,
      critical_path_end_date: null,
      planned_duration_days: null,
      is_kafka_processed: true,
      is_unique: true,
      created_date: '2023-07-03T15:50:40.726232',
      created_by: 'Z0028NX',
      updated_date: '2023-07-06T19:51:21.527963',
      updated_by: 'Z007XK9',
      schedule_milestone_tasks: [],
      milestone_name: 'Adult Beverage',
    },
    {
      schedule_milestone_id: 1474889,
      schedule_id: 98579,
      milestone_id: 26,
      model_date: null,
      planned_start_date: null,
      planned_end_date: null,
      forecasted_start_date: null,
      forecasted_end_date: null,
      actual_start_date: null,
      actual_end_date: null,
      critical_path_start_date: null,
      critical_path_end_date: null,
      planned_duration_days: null,
      is_kafka_processed: true,
      is_unique: false,
      created_date: '2023-07-03T15:50:40.736082',
      created_by: 'Z0028NX',
      updated_date: null,
      updated_by: null,
      schedule_milestone_tasks: [],
      milestone_name: 'Beauty',
    },
    {
      schedule_milestone_id: 1474890,
      schedule_id: 98579,
      milestone_id: 10,
      model_date: null,
      planned_start_date: null,
      planned_end_date: null,
      forecasted_start_date: null,
      forecasted_end_date: null,
      actual_start_date: null,
      actual_end_date: null,
      critical_path_start_date: null,
      critical_path_end_date: null,
      planned_duration_days: null,
      is_kafka_processed: true,
      is_unique: false,
      created_date: '2023-07-03T15:50:40.739821',
      created_by: 'Z0028NX',
      updated_date: null,
      updated_by: null,
      schedule_milestone_tasks: [],
      milestone_name: 'Bakery',
    },
    {
      schedule_milestone_id: 1474891,
      schedule_id: 98579,
      milestone_id: 18,
      model_date: null,
      planned_start_date: null,
      planned_end_date: null,
      forecasted_start_date: '2023-07-21',
      forecasted_end_date: '2023-07-21',
      actual_start_date: null,
      actual_end_date: null,
      critical_path_start_date: null,
      critical_path_end_date: null,
      planned_duration_days: null,
      is_kafka_processed: true,
      is_unique: true,
      created_date: '2023-07-03T15:50:40.744264',
      created_by: 'Z0028NX',
      updated_date: '2023-07-06T17:50:32.486284',
      updated_by: 'Z007XK9',
      schedule_milestone_tasks: [],
      milestone_name: 'Accessories',
    },
    {
      schedule_milestone_id: 1474892,
      schedule_id: 98579,
      milestone_id: 23,
      model_date: null,
      planned_start_date: null,
      planned_end_date: null,
      forecasted_start_date: null,
      forecasted_end_date: null,
      actual_start_date: null,
      actual_end_date: null,
      critical_path_start_date: null,
      critical_path_end_date: null,
      planned_duration_days: null,
      is_kafka_processed: true,
      is_unique: false,
      created_date: '2023-07-03T15:50:40.748227',
      created_by: 'Z0028NX',
      updated_date: null,
      updated_by: null,
      schedule_milestone_tasks: [],
      milestone_name: 'Guest Ready',
    },
    {
      schedule_milestone_id: 1474893,
      schedule_id: 98579,
      milestone_id: 9,
      model_date: null,
      planned_start_date: '2023-07-06',
      planned_end_date: '2023-07-14',
      forecasted_start_date: null,
      forecasted_end_date: null,
      actual_start_date: null,
      actual_end_date: null,
      critical_path_start_date: null,
      critical_path_end_date: null,
      planned_duration_days: null,
      is_kafka_processed: true,
      is_unique: true,
      created_date: '2023-07-03T15:50:40.752494',
      created_by: 'Z0028NX',
      updated_date: '2023-07-06T17:50:32.462067',
      updated_by: 'Z007XK9',
      schedule_milestone_tasks: [],
      milestone_name: 'Construction Turnover',
    },
  ],
  APP_HEADER: 'CPM Milestones',
  MOBILE_BASE_PATH: '/mobile',
  LOCATION_PARAM: 'location_id',
  LOCATION_PATH: '/locations',
  PROJECT_PATH: '/projects',
  PROJECT_PARAM: 'project_id',
  PROGRAM_TYPE_PARAM: 'program_type_id',
  MILESTONE_PARAM: 'milestone_id',
  MILESTONES_PATH: '/milestones',
  MILESTONE_DETAILS_PATH: '/milestones/:milestone_id',
  SCHEDULE_MILESTONE: 'SCHEDULEMILESTONE',
  FILE_NAME_PROPERTY: 'file_name',
  FILE_TOSS_KEY_FIELD: 'schedule_milestone_toss_object_key',
  FILE_NAME_FIELD: 'schedule_milestone_toss_file_name',
  MAX_FILE_SIZE: 25000000,
  SCHEDULE_VIEW_TYPE_PARAM: 'schedule_view_type_id',
  NEW_STORE_ID: 71594,
}

export default constants
