import axios from 'axios'
import apiConfig from '../apiConfig'

const AxiosUtil = axios.create({
  headers: {
    Authorization: localStorage?.getItem?.('access_token') ?? '',
    'x-api-key': apiConfig.apiKey,
  },
})

export { AxiosUtil }
