// import { Input } from '@enterprise-ui/canvas-ui-react'

import { ReactNode } from 'react'
import { useLocation, useNavigate, matchPath } from 'react-router-dom'
import EnterpriseIcon, { PencilIcon } from '@enterprise-ui/icons'
import constants from '../utilities/constants'
import { useAppContext } from '../Context'
import SkeletonCpm from './SkeletonCpm'
import { Button } from '@enterprise-ui/canvas-ui-react'

const PageHeader = () => {
  const {
    selectedProgramName,
    selectedProgramType,
    selectedLocation,
    setProjects,
    setSelectedProgramType,
    selectedMilestone,
  } = useAppContext()
  const location = useLocation()
  const navigate = useNavigate()
  const searchParams = new URLSearchParams(location?.search)
  const locationParam = searchParams?.get?.(constants.LOCATION_PARAM)
  const projectParam = searchParams.get(constants.PROJECT_PARAM)
  const programTypeParam = searchParams.get(constants.PROGRAM_TYPE_PARAM)

  const isLocationPath = matchPath(
    {
      path: constants.LOCATION_PATH,
    },
    location.pathname,
  )

  // const isProjectPath = matchPath(
  //   {
  //     path: constants.PROJECT_PATH,
  //   },
  //   location.pathname,
  // )

  const isMileStonesPath = matchPath(
    {
      path: constants.MILESTONES_PATH,
    },
    location.pathname,
  )

  const isMilestoneDetailsPath = matchPath(
    {
      path: constants.MILESTONE_DETAILS_PATH,
    },
    location.pathname,
  )

  function mobilePageHeader() {
    const HeaderItem = ({
      children,
      redirectFn,
      loading,
      ariaLabel,
    }: {
      children: ReactNode
      redirectFn: Function
      loading?: boolean
      ariaLabel: string
    }) => (
      <div className="page-header-item">
        {loading ? (
          <SkeletonCpm highlightColor="white" height={20} />
        ) : (
          <>
            {children}
            <Button iconOnly aria-label={ariaLabel} type="default">
              <EnterpriseIcon
                icon={PencilIcon}
                // size="inline"
                className="clickable"
                onClick={() => {
                  redirectFn()
                }}
              />
            </Button>
          </>
        )}
      </div>
    )

    return (
      <>
        <div className="hc-mb-lg page-header-container">
          {!isLocationPath && (
            <HeaderItem
              redirectFn={() => {
                navigate('/locations')
              }}
              loading={!selectedLocation}
              ariaLabel="Edit selected Location"
            >
              <div>Location:</div>
              <strong>{selectedLocation}</strong>
            </HeaderItem>
          )}
          {(isMileStonesPath || isMilestoneDetailsPath) && (
            <>
              <HeaderItem
                redirectFn={() => {
                  navigate({
                    pathname: constants.PROJECT_PATH,
                    search: new URLSearchParams({
                      [constants.LOCATION_PARAM]: locationParam ?? '',
                    }).toString(),
                  })
                  setSelectedProgramType(null)
                  setProjects(null)
                }}
                loading={!selectedProgramType}
                ariaLabel="Edit selected Program Type"
              >
                <div>Program Type:</div>
                <strong>{selectedProgramType?.label ?? ''}</strong>
              </HeaderItem>
              <HeaderItem
                redirectFn={() => {
                  navigate({
                    pathname: constants.PROJECT_PATH,
                    search: new URLSearchParams({
                      [constants.LOCATION_PARAM]: locationParam ?? '',
                    }).toString(),
                  })
                }}
                loading={!selectedProgramName}
                ariaLabel="Edit selected Program"
              >
                <div>Program Name:</div>
                <strong>{selectedProgramName ?? ''}</strong>
              </HeaderItem>
            </>
          )}
          {isMilestoneDetailsPath && (
            <HeaderItem
              redirectFn={() => {
                navigate({
                  pathname: constants.MILESTONES_PATH,
                  search: new URLSearchParams({
                    [constants.LOCATION_PARAM]: locationParam ?? '',
                    [constants.PROJECT_PARAM]: projectParam ?? '',
                    [constants.PROGRAM_TYPE_PARAM]: programTypeParam ?? '',
                  }).toString(),
                })
              }}
              ariaLabel="Edit selected Milestone"
            >
              <>
                <div>Milestone:</div>
                <strong>{selectedMilestone?.name}</strong>
              </>
            </HeaderItem>
          )}
        </div>
      </>
    )
  }

  // Keeping the commented code below if required. Will remove during the final cleanup
  // function breadcrumbPageheader() {
  //   const BreadcrumbItem = ({
  //     children,
  //     redirectFn,
  //     loading,
  //   }: {
  //     children: ReactNode
  //     redirectFn: Function
  //     loading?: boolean
  //   }) => (
  //     <Breadcrumbs.Item onClick={() => redirectFn()}>
  //       {loading ? (
  //         <Skeleton
  //           baseColor="lightGray"
  //           highlightColor="white"
  //           width="80vw"
  //           height={20}
  //         />
  //       ) : (
  //         <>{children}</>
  //       )}
  //     </Breadcrumbs.Item>
  //   )

  //   return (
  //     <>
  //       <Breadcrumbs className="hc-mb-lg">
  //         {!isLocationPath && (
  //           <BreadcrumbItem
  //             redirectFn={() => {
  //               navigate('/locations')
  //             }}
  //             loading={!selectedLocation}
  //           >
  //             {`Location: ${selectedLocation}`}
  //           </BreadcrumbItem>
  //         )}
  //         {(isMileStonesPath || isMilestoneDetailsPath) && (
  //           <BreadcrumbItem
  //             redirectFn={() => {
  //               navigate({
  //                 pathname: constants.PROJECT_PATH,
  //                 search: new URLSearchParams({
  //                   [constants.LOCATION_PARAM]: locationParam ?? '',
  //                 }).toString(),
  //               })
  //             }}
  //             loading={!selectedProgramName}
  //           >
  //             {`Program Name: ${selectedProgramName ?? ''}`}
  //           </BreadcrumbItem>
  //         )}
  //         {isMileStonesPath && (
  //           <Breadcrumbs.Item>Select Milestone</Breadcrumbs.Item>
  //         )}
  //         {isMilestoneDetailsPath && (
  //           <BreadcrumbItem
  //             redirectFn={() => {
  //               navigate({
  //                 pathname: constants.MILESTONES_PATH,
  //                 search: new URLSearchParams({
  //                   [constants.LOCATION_PARAM]: locationParam ?? '',
  //                   [constants.PROJECT_PARAM]: projectParam ?? '',
  //                   [constants.PROGRAM_TYPE_PARAM]: programTypeParam ?? '',
  //                 }).toString(),
  //               })
  //             }}
  //           >
  //             {`Milestone: ${selectedMilestone?.name}`}
  //           </BreadcrumbItem>
  //         )}
  //         {isMilestoneDetailsPath && (
  //           <Breadcrumbs.Item>Edit Milestone</Breadcrumbs.Item>
  //         )}
  //       </Breadcrumbs>
  //     </>
  //   )
  // }

  return mobilePageHeader()
}

export default PageHeader
