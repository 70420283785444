import { useNavigate } from 'react-router-dom'
import { Button, Grid, Heading } from '@enterprise-ui/canvas-ui-react'
import { useEffect } from 'react'
import { useAppContext } from '../../Context'

const PageNotFound = (props: any) => {
  const navigate = useNavigate()
  const { setPageNotFound } = useAppContext()

  useEffect(() => {
    setPageNotFound(true)
    return () => {
      setPageNotFound(false)
    }
  }, [])
  return (
    <Grid.Container align="center" justify="center">
      <Grid.Item xs={12}>
        <Heading className="hc-ta-center" size={3}>
          Page Not Found
        </Heading>
      </Grid.Item>
      <Grid.Item>
        <Button onClick={() => navigate(-1)}>Go Back</Button>
      </Grid.Item>
      <Grid.Item>
        <Button onClick={() => navigate('/')}>Go Home</Button>
      </Grid.Item>
    </Grid.Container>
  )
}

export default PageNotFound
