import React from 'react'

export default function MilestoneColorCodeLegend() {
  return (
    <div className="legend-container">
      <div className="legend-item">
        <div className="legend-color cpm-milestone-red"></div>
        <span className="hc-pl-md hc-ws-no-wrap"> Milestone is past due</span>
      </div>
      <div className="legend-item">
        <div className="legend-color cpm-milestone-purple"></div>
        <span className="hc-pl-md hc-ws-no-wrap">Milestone due in 14 days</span>
      </div>
      <div className="legend-item">
        <div className="legend-color cpm-milestone-blue"></div>
        <span className="hc-pl-md hc-ws-no-wrap"> Milestone is delayed</span>
      </div>
      <div className="legend-item">
        <div className="legend-color cpm-milestone-orange"></div>
        <span className="hc-pl-md hc-ws-no-wrap">Milestone completed late</span>
      </div>
      <div className="legend-item">
        <div className="legend-color cpm-milestone-green"></div>
        <span className="hc-pl-md hc-ws-no-wrap">
          Milestone completed on time
        </span>
      </div>
    </div>
  )
}
