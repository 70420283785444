import { useEffect } from 'react'
import { useAuth } from '@praxis/component-auth'
import { AxiosUtil } from '../utilities/AxiosUtil'
import apiConfig from '../apiConfig'

import {
  ProgramTypeProps,
  iScheduleViewType,
  iScheduleMilestone,
  iReasonForDelay,
} from '../types/types'
import { useAppContext } from '../Context'

export default function useServices() {
  const { setNetworkError } = useAppContext()
  const auth = useAuth()

  useEffect(() => {
    setNetworkError(false)
  }, [])

  // custom function to call an API using async await or the normal callback .then logic.
  //The "cb" argument is the callback function that calls the function from parent passing the result in it
  async function callApi({
    promise,
    cb,
    transformFn,
    errorCb,
    errorString,
  }: {
    promise: Promise<any>
    cb?: Function | null
    transformFn?: Function | null
    errorCb?: string
    errorString?: string
  }) {
    try {
      const result = await promise
      if (cb) {
        return cb(transformFn?.(result?.data) ?? result?.data ?? [])
      } else {
        return transformFn?.(result?.data) ?? result?.data ?? []
      }
    } catch (error: any) {
      // error callback if needs to be handled in the calling function
      if (errorCb) {
        throw error
      } else {
        //for debugging
        console.log('Error Log - ', error)
        setNetworkError(errorString || true)
        return null
      }
    }
  }

  async function getAllLocations(cb?: Function | null) {
    const locationUrl = `${
      apiConfig?.services?.cpmCommons ?? ''
    }/mobile/locations`
    return callApi({
      promise: AxiosUtil.get(locationUrl, {
        // adding authorization just here since its the first api called when logging out and logging in. During that scenario, the first api call always
        // fails probably because of a the localstorage being cleared and not being set. Need to revisit
        headers: {
          Authorization: `Bearer ${auth?.session?.accessToken}`,
        },
      }),
      cb,
      errorString: 'Error getting location details',
    })
  }

  async function getSingleLocationDetails(
    locationId: string,
    cb?: Function | null,
  ) {
    const locationUrl = `${
      apiConfig?.services?.cpmCommons ?? ''
    }/locations/${locationId}`
    return callApi({
      promise: AxiosUtil.get(locationUrl, {
        headers: {
          Authorization: `Bearer ${auth?.session?.accessToken}`,
        },
      }),
      cb,
      errorString: `Error getting location details for location ${locationId}`,
    })
  }

  async function getProgramTypes(cb?: Function | null) {
    const programTypesUrl = `${apiConfig?.services?.procore ?? ''}/programs`
    return callApi({
      promise: AxiosUtil.get(programTypesUrl, {
        headers: {
          Authorization: `Bearer ${auth?.session?.accessToken}`,
        },
      }),
      cb,
      transformFn: (response: ProgramTypeProps[]) =>
        response?.map((val: ProgramTypeProps) => ({
          id: val.program_id,
          label: val.program_name,
          value: val.program_id,
        })) ?? [],
      errorString: `Error getting program Types`,
    })
  }

  async function getProjectsForLocation(
    locationId: string,
    cb?: Function | null,
  ) {
    const projectForLocationUrl = `${apiConfig?.services?.ppmProjects}/mobile/projects?location_id=${locationId}`
    return callApi({
      promise: AxiosUtil.get(projectForLocationUrl, {
        headers: {
          Authorization: `Bearer ${auth?.session?.accessToken}`,
        },
      }),
      cb,
      errorString: `Error getting Projects for location ${locationId}`,
    })
  }

  async function getAllScheduleViewTypes(cb?: Function | null) {
    const scheduleViewTypesUrl = `${
      apiConfig?.services?.schedules ?? ''
    }/schedule_view_types`
    return callApi({
      promise: AxiosUtil.get(scheduleViewTypesUrl, {
        headers: {
          Authorization: `Bearer ${auth?.session?.accessToken}`,
        },
      }),
      cb,
      transformFn: (response: iScheduleViewType[]) =>
        response?.map((val: iScheduleViewType) => ({
          id: val.schedule_view_type_id,
          label: val.schedule_view_type_name,
          value: val.schedule_view_type_id,
        })) ?? [],
      errorString: `Error getting Schedule View Types`,
    })
  }

  async function getAllReasonsForDelay(cb?: Function | null) {
    const reasonForDelayUrl = `${
      apiConfig?.services?.schedules ?? ''
    }/milestones/reason_for_delays`
    return callApi({
      promise: AxiosUtil.get(reasonForDelayUrl, {
        headers: {
          Authorization: `Bearer ${auth?.session?.accessToken}`,
        },
      }),
      cb,
      transformFn: (response: iReasonForDelay[]) =>
        response?.map((val: iReasonForDelay) => ({
          id: val.reason_for_delay_id,
          label: val.reason_for_delay_value,
          value: val.reason_for_delay_id,
        })) ?? [],
      errorString: `Error getting Reasons for delay`,
    })
  }

  async function getAllMilestones(cb?: Function | null) {
    const allMilestonesUrl = `${
      apiConfig?.services?.schedules ?? ''
    }/milestones`
    return callApi({
      promise: AxiosUtil.get(allMilestonesUrl, {
        headers: {
          Authorization: `Bearer ${auth?.session?.accessToken}`,
        },
      }),
      cb,
      errorString: `Error getting Milestones`,
    })
  }

  async function getScheduleMilestonesForProject(
    projectId: string,
    cb?: Function | null,
  ) {
    const scheduleMilestonesForProjectUrl = `${
      apiConfig?.services?.schedules ?? ''
    }/project_schedules/source_id/${projectId}`
    return callApi({
      promise: AxiosUtil.get(scheduleMilestonesForProjectUrl, {
        headers: {
          Authorization: `Bearer ${auth?.session?.accessToken}`,
        },
      }),
      cb,
      errorString: `Error getting Schedule Milestones for Project ${projectId}`,
    })
  }

  async function getAllScheduleTemplates(cb?: Function | null) {
    const scheduleTemplatesUrl = `${
      apiConfig?.services?.schedules ?? ''
    }/schedule_templates`
    return callApi({
      promise: AxiosUtil.get(scheduleTemplatesUrl, {
        headers: {
          Authorization: `Bearer ${auth?.session?.accessToken}`,
        },
      }),
      cb,
      errorString: 'Error getting all schedule types',
    })
  }

  async function saveScheduleMilestone(
    scheduleMilestone: iScheduleMilestone,
    errorCb?: string,
  ) {
    const saveScheduleMilestoneUrl = `${
      apiConfig?.services?.schedules ?? ''
    }/schedule_milestones`
    return callApi({
      promise: AxiosUtil.put(saveScheduleMilestoneUrl, [scheduleMilestone], {
        headers: {
          Authorization: `Bearer ${auth?.session?.accessToken}`,
        },
      }),
      errorCb,
    })
  }

  async function uploadFiles(
    sourceType: string,
    sourceId: number | null | undefined,
    filesForUpload: any,
    errorCb?: string,
  ) {
    const uploadFilesUrl = `${apiConfig.services.cpmCommons}/toss/${sourceType}/${sourceId}`
    const formData = new FormData()

    filesForUpload.forEach((fileForUpload: any) => {
      const file = new File([fileForUpload.file], fileForUpload.name, {
        type: fileForUpload.file.type,
      })
      formData.append('files', file)
    })

    return callApi({
      promise: AxiosUtil.post(uploadFilesUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${auth?.session?.accessToken}`,
        },
      }),
      errorCb,
    })
  }

  async function downloadFile(
    sourceType: string,
    tossKey: string,
    fileName: string,
    fileContentType: string,
    cb?: Function | null,
    errorCb?: string,
  ) {
    const finalUrl = `${apiConfig.services.cpmCommons}/toss?source_type=${sourceType}&object_key=${tossKey}&file_name=${fileName}&file_content_type=${fileContentType}`
    return callApi({
      promise: AxiosUtil.get(finalUrl, {
        headers: {
          Authorization: `Bearer ${auth?.session?.accessToken}`,
        },
        responseType: 'blob',
      }),
      cb,
      errorCb,
    })
  }

  async function deleteFiles(
    sourceType: string,
    sourceId: number | null | undefined,
    tossKeys: string[],
    cb?: Function | null,
    errorCb?: string,
  ) {
    const finalUrl = `${apiConfig.services.cpmCommons}/toss/${sourceType}/${sourceId}?toss_keys=${tossKeys}`
    return callApi({
      promise: AxiosUtil.delete(finalUrl, {
        headers: {
          Authorization: `Bearer ${auth?.session?.accessToken}`,
        },
      }),
      cb,
      errorCb,
    })
  }

  async function onDemandScanFiles(filesToScan: any, errorCb?: string) {
    const onDemandScanurl = `${apiConfig.services.cpmCommons}/on_demand_scan`
    const formData = new FormData()

    filesToScan.forEach((fileForUpload: any) => {
      let file = fileForUpload.file
      formData.append('files', file)
    })

    return callApi({
      promise: AxiosUtil.post(onDemandScanurl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${auth?.session?.accessToken}`,
        },
      }),
      errorCb,
    })
  }
  async function getAllMilestonesAdditionalAttributes(cb?: Function | null) {
    const milestoneAttributesUrl = `${
      apiConfig?.services?.schedules ?? ''
    }/milestones/additional_attributes`
    return callApi({
      promise: AxiosUtil.get(milestoneAttributesUrl, {
        headers: {
          Authorization: `Bearer ${auth?.session?.accessToken}`,
        },
      }),
      cb,
      errorString: `Error getting Milestone additional attributes`,
    })
  }

  async function checkIfTeamMember(
    sourceIds: number[],
    sourceType: string,
    cb?: Function | null,
  ) {
    const url = `${
      apiConfig?.services?.teamsResourceUrl ?? ''
    }/assigned_sources`
    let body = {
      source_ids: sourceIds,
      source_type: sourceType,
    }
    return callApi({
      promise: AxiosUtil.post(url, body, {
        headers: {
          Authorization: `Bearer ${auth?.session?.accessToken}`,
        },
      }),
      cb,
      errorString: `Error checking assigned projects for the user`,
    })
  }

  return {
    getAllLocations,
    getSingleLocationDetails,
    getProjectsForLocation,
    getProgramTypes,
    getAllScheduleViewTypes,
    getAllMilestones,
    getScheduleMilestonesForProject,
    getAllScheduleTemplates,
    saveScheduleMilestone,
    uploadFiles,
    downloadFile,
    deleteFiles,
    onDemandScanFiles,
    getAllReasonsForDelay,
    getAllMilestonesAdditionalAttributes,
    checkIfTeamMember,
  }
}
