/*
👋 “Hey” from the Praxis team!
'apiConfig' is one pattern that can be used to set environment-based values at build time.
This happens by creating an environment variable REACT_APP_ENV, and then matching corresponding
keys to each environment in the 'envConfigs' object below. To learn more about how to set
REACT_APP_ENV, view the README generated with this app.
For an alternative pattern - using TAP configuration to set environment-based values at cluster initialization - see:
@praxis/component-runtime-env - https://praxis.prod.target.com/components/runtime-env
*/

import merge from 'lodash/merge'

// Configurations shared between most or all environments can be stored in commonConfig.
const commonConfig: any = {
  auth: {
    clientId: 'capitalprojects_npe_im',
    authorizationUrl:
      'https://oauth.iam.perf.target.com/auth/oauth/v2/authorize',
    logoutUrl:
      'https://logonservices.iam.perf.target.com/login/responses/logoff.html',
    redirectUri: window.location.origin,
  },
  authPol: {
    clientId: 'capitalprojects_npe_im',
    authorizationUrl:
      'https://oauth.iam.perf.partnersonline.com/auth/oauth/v2/tgt/authorize/nla/1',
    logoutUrl:
      'https://logonservices.oauth.iam.perf.partnersonline.com/login/responses/logoff.html',
    redirectUri: window.location.origin,
  },
}

const envConfigs: any = {
  dev: {
    apiKey: '00ca0235d0b52d9604e6f92dee9d92b167703233',
    // auth and authPol configs will be merged from commonConfig
    services: {
      cpmCommons:
        'https://cpmcommons-api.dev.target.com/capital_project_common_endpoints/v1',
      ppmProjects:
        'https://ppm-projects-api.dev.target.com/property_projects/v1',
      procore: 'https://stage-api.target.com/cpm_outbound_project_settings/v1',
      schedules:
        'https://propertyprojectschedules.dev.target.com/property_project_schedules/v4',
      teamsResourceUrl: 'https://propertiesteamapi.dev.target.com/teams/v1',
    },
  },
  stg: {
    apiKey: '00ca0235d0b52d9604e6f92dee9d92b167703233',
    // auth and authPol configs will be merged from commonConfig
    services: {
      cpmCommons:
        'https://stage-api.target.com/capital_project_common_endpoints/v1',
      ppmProjects: 'https://stage-api.target.com/property_projects/v1',
      procore: 'https://stage-api.target.com/cpm_outbound_project_settings/v1',
      schedules: 'https://stage-api.target.com/property_project_schedules/v4',
      teamsResourceUrl: 'https://stage-api.target.com/teams/v1',
    },
  },
  prod: {
    apiKey: '2b8f4540eca6b0c18b1f6dd54956fe3f7f633349',
    auth: {
      clientId: 'capitalprojects_prod_im',
      authorizationUrl: 'https://oauth.iam.target.com/auth/oauth/v2/authorize',
      logoutUrl:
        'https://logonservices.iam.target.com/login/responses/logoff.html',
    },
    authPol: {
      clientId: 'capitalprojects_prod_im',
      authorizationUrl:
        'https://oauth.iam.partnersonline.com/auth/oauth/v2/tgt/authorize/nla/1',
      logoutUrl:
        'https://logonservices.oauth.iam.partnersonline.com/login/responses/logoff.html',
    },
    services: {
      cpmCommons: 'https://api.target.com/capital_project_common_endpoints/v1',
      ppmProjects: 'https://api.target.com/property_projects/v1',
      procore: 'https://api.target.com/cpm_outbound_project_settings/v1',
      schedules: 'https://api.target.com/property_project_schedules/v4',
      teamsResourceUrl: 'https://api.target.com/teams/v1',
    },
  },
}

const appEnv = process.env.REACT_APP_ENV
const config = appEnv != null ? envConfigs[appEnv] : {}

// commonConfig and the config for the matching REACT_APP_ENV are combined.
// Values in the environment-specific config will overwrite commonConfig keys
// if they share the same name.
export const apiConfig = merge(commonConfig, config)

export default apiConfig
