import { useRef, useMemo, useState, useEffect } from 'react'
import { AgGridReact } from 'ag-grid-react'
import AgGridLoading from './AgGridLoading'
import { useAppContext } from '../Context'

interface AgGridProps {
  data: any
  columns: any
  loading?: boolean
}
const AgGridCpm = (props: AgGridProps) => {
  const { data, columns, loading } = props
  const { darkMode } = useAppContext()
  const gridRef = useRef<AgGridReact>(null)
  const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), [])
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), [])
  const [totalRows, setTotalRows] = useState<number | null>(null)

  useEffect(() => {
    if (data?.length) {
      setTotalRows(data.length)
      gridRef?.current?.api?.setFilterModel(null)
    }
  }, [data])

  return loading ? (
    <AgGridLoading />
  ) : (
    <div style={containerStyle}>
      {totalRows !== null ? (
        <strong>{`Total Rows: ${totalRows}`}</strong>
      ) : null}
      <div
        style={gridStyle}
        className={
          darkMode === 'true' ? 'ag-theme-alpine-dark' : 'ag-theme-alpine'
        }
      >
        <AgGridReact
          ref={gridRef}
          rowData={data}
          columnDefs={columns}
          onFilterChanged={() => {
            if (gridRef?.current!.api?.getDisplayedRowCount?.() === 0) {
              gridRef?.current?.api?.showNoRowsOverlay()
            } else {
              gridRef?.current?.api?.hideOverlay()
            }
            setTotalRows(gridRef?.current!.api?.getDisplayedRowCount?.())
          }}
          onGridReady={() => {
            setTotalRows(gridRef?.current!.api?.getDisplayedRowCount?.())
          }}
        />
      </div>
    </div>
  )
}

export default AgGridCpm
