import { useEffect, useState } from 'react'
import constants from '../../utilities/constants'
import { ToastProvider } from '@enterprise-ui/canvas-ui-react'
import { useAppContext } from '../../Context'
import useServices from '../../services/useServices'
import { iScheduleMilestone } from '../../types/types'
const { FILE_NAME_FIELD } = constants

export function useFileValidation(): [
  validating: boolean,
  setSelectedFilesToUpload: Function,
] {
  const makeToast = ToastProvider.useToaster()
  const {
    scheduleMilestoneFiles,
    setScheduleMilestoneFiles,
    scheduleMilestoneToEdit,
  } = useAppContext()
  const { onDemandScanFiles } = useServices()
  const [validating, setValidating] = useState(false)
  const [selectedFilesToUpload, setSelectedFilesToUpload] = useState([])
  const filesAlreadyUploaded =
    scheduleMilestoneToEdit?.schedule_milestone_toss_files ?? []

  const startFileScan = async (filesToScan: any) => {
    try {
      const result = await onDemandScanFiles(filesToScan, 'throwIfError')

      const notCleanFiles = result?.filter?.(
        (val: { is_file_clean: any }) => val.is_file_clean === false,
      )

      if (notCleanFiles?.length) {
        const notCleanFilesMessage = notCleanFiles
          ?.map((val: { [x: string]: any }) => val.file_name)
          ?.join?.(', ')
          ?.concat?.(' did not pass the scan. Please check.')
        makeToast({
          type: 'alert',
          autoHideDuration: 20000,
          heading: 'Alert',
          message: notCleanFilesMessage,
        })
      }
      // Remove files that are not clean from the list of files that are accepted for upload
      let filesToScanCopy = [...filesToScan]
        .slice()
        .filter(
          (val: any) =>
            !notCleanFiles.find(
              (innerval: any) => innerval.file_name === val.name,
            ),
        )

      setScheduleMilestoneFiles(
        (scheduleMilestoneFiles ?? []).concat(filesToScanCopy),
      )
      setSelectedFilesToUpload([])
      setValidating(false)
    } catch (error) {
      //catch block
      makeToast({
        type: 'error',
        autoHideDuration: 20000,
        message: `Error scanning files! Please try again.`,
      })
      console.log(error)
    }
  }

  useEffect(() => {
    if (selectedFilesToUpload?.length) {
      setValidating(true)
      const fileAlreadyExists =
        [...selectedFilesToUpload]
          ?.filter?.(
            (fileSelectedForUpload: HTMLInputElement) =>
              scheduleMilestoneFiles?.find?.(
                (innerval: { [x: string]: any }) =>
                  innerval.name === fileSelectedForUpload.name,
              ) ||
              filesAlreadyUploaded?.find?.(
                (innerval: { [x: string]: any }) =>
                  innerval[FILE_NAME_FIELD] === fileSelectedForUpload.name,
              ),
          )
          ?.map?.(
            (fileSelectedForUpload: HTMLInputElement) =>
              fileSelectedForUpload.name,
          ) ?? []
      const largerFileExists =
        [...selectedFilesToUpload]
          ?.filter?.(
            (fileSelectedForUpload: HTMLInputElement) =>
              fileSelectedForUpload.size > constants.MAX_FILE_SIZE,
          )
          ?.map?.(
            (fileSelectedForUpload: HTMLInputElement) =>
              fileSelectedForUpload.name,
          ) ?? []

      if (fileAlreadyExists?.length || largerFileExists?.length) {
        const messageTextSize = largerFileExists?.length
          ? largerFileExists
              ?.join?.(', ')
              .concat(` file(s) has size greater than 25MB.`)
          : ''
        const messageTextDup = fileAlreadyExists?.length
          ? fileAlreadyExists
              ?.join?.(', ')
              .concat(
                ' already exists in the uploaded files or list of files to upload',
              )
          : ''
        makeToast({
          type: 'alert',
          autoHideDuration: 20000,
          heading: 'Alert',
          message: (
            <span className="file-validation-messages-toaster-div">
              <span>{messageTextSize}</span>
              <span>{messageTextDup}</span>
            </span>
          ),
        })
      }

      const acceptedFiles =
        [...selectedFilesToUpload]
          ?.filter?.(
            (fileSelectedForUpload: HTMLInputElement) =>
              fileSelectedForUpload.size < constants.MAX_FILE_SIZE &&
              !filesAlreadyUploaded?.find?.(
                (existingFiles: any) =>
                  existingFiles[FILE_NAME_FIELD] === fileSelectedForUpload.name,
              ) &&
              !scheduleMilestoneFiles?.find?.(
                (innerval: { [x: string]: string }) =>
                  innerval.name === fileSelectedForUpload.name,
              ),
          )
          ?.map?.(
            (fileSelectedForUpload: HTMLInputElement) => fileSelectedForUpload,
          ) ?? []
      // start on demand scan
      if (acceptedFiles?.length > 0) {
        startFileScan(acceptedFiles)
      } else {
        setValidating(false)
      }
    }
  }, [selectedFilesToUpload])

  return [validating, setSelectedFilesToUpload]
}

export function convertFileObjectToArray(
  files: any,
  scheduleMilestoneToEdit: iScheduleMilestone | null,
  projectId: string,
  locationId: string,
  fileRequired: boolean,
  actualEndDateChanged: boolean,
) {
  return Array.from(files).map((val: any) => {
    //Convert file name to the required format here since all files being uploaded in mobile would be in this format as per new requirement
    const fileName = scheduleMilestoneToEdit?.milestone_name
      ?.concat('_')
      .concat(projectId)
      .concat('_')
      .concat(locationId)
      .concat('_')
      .concat(val.name)
    return {
      lastModified: parseInt(val.lastModified),
      lastModifiedDate: String(val.lastModifiedDate),
      name: String(fileRequired && actualEndDateChanged ? fileName : val.name),
      size: parseInt(val.size),
      type: String(val.type),
      edit: false,
      file: val,
    }
  })
}

export function isValidImage(file: any) {
  const fileType = file['type']
  const validImageTypes = [
    'image/gif',
    'image/jpeg',
    'image/png',
    'image/svg+xml',
  ]
  return validImageTypes.includes(fileType)
}
