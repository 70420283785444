import moment from 'moment'

export function getUrlParam(paramKey: string) {
  const searchParams = new URLSearchParams(window?.location?.search)
  return searchParams?.get?.(paramKey) ?? ''
}

export function isDatePast(dt: string | null) {
  return !dt ? false : moment().isAfter(dt) ? true : false
}

export function isDateWithinTwoWeeks(dt: string | null) {
  return moment(dt).diff(moment(), 'days') < 14 ? true : false
}

export function isPOLSessionValid(userId: string, Cookies: any): boolean {
  const ssoSessionInfo = Cookies.get('SSOSESSIONINFO') || '' //oAuth will set this cookie
  if (ssoSessionInfo) {
    const ssoSessionObj = JSON.parse(window.atob(ssoSessionInfo)) //Decode session and parse to json object
    const { user } = ssoSessionObj
    return user && user.toString().toUpperCase() === userId.toUpperCase()
  } else {
    return true
  }
}
