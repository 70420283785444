import { iScheduleMilestone } from '../../types/types'
import { isDatePast, isDateWithinTwoWeeks } from '../../utilities'

// actual end date populated and on or before planned end date  - green color
const completedOnTime = (milestone: iScheduleMilestone) =>
  milestone?.actual_end_date &&
  milestone?.planned_end_date &&
  milestone?.actual_end_date <= milestone?.planned_end_date

// actual end date populated and is after planned end date - orange color
const completedLate = (milestone: iScheduleMilestone) =>
  milestone?.actual_end_date &&
  milestone?.planned_end_date &&
  milestone?.actual_end_date > milestone?.planned_end_date

// no actual end date , planned or forecast date is past due - if forecast date is null, check for planned date - red color
const pastDue = (milestone: iScheduleMilestone) => {
  if (!milestone?.actual_end_date) {
    if (
      milestone.forecasted_end_date &&
      isDatePast(milestone?.forecasted_end_date)
    ) {
      return true
    } else {
      if (!milestone.forecasted_end_date) {
        if (
          milestone?.planned_end_date &&
          isDatePast(milestone?.planned_end_date)
        ) {
          return true
        }
      }
    }
  }
}
// no actual end date , planned date is past due , forecast date is in future - blue color
const pastDueWithFutureForecast = (milestone: iScheduleMilestone) =>
  !milestone?.actual_end_date &&
  milestone?.planned_end_date &&
  milestone.forecasted_end_date &&
  isDatePast(milestone?.planned_end_date) &&
  !isDatePast(milestone?.forecasted_end_date)

//Milestone is coming up in next 14 days . First check for forecast end date and if not, check for planned end date - purple color
const dueInTwoWeeks = (milestone: iScheduleMilestone) =>
  !milestone?.actual_end_date &&
  (milestone?.forecasted_end_date || milestone.planned_end_date) &&
  isDateWithinTwoWeeks(
    milestone?.forecasted_end_date ?? milestone.planned_end_date,
  )

// has only planned date
const hasOnlyPlannedDate = (milestone: iScheduleMilestone) =>
  !milestone?.actual_end_date && milestone?.planned_end_date

export function getMilestoneCardClassNames(milestone: iScheduleMilestone) {
  //Green Color
  if (completedOnTime(milestone)) {
    return 'hc-pa-md cpm-milestone-green'
  }

  // Orange Color
  if (completedLate(milestone)) {
    return 'hc-pa-md cpm-milestone-orange'
  }

  //Red Color
  if (pastDue(milestone)) {
    return 'hc-pa-md cpm-milestone-red'
  }

  // Purple Color
  if (dueInTwoWeeks(milestone)) {
    return 'hc-pa-md cpm-milestone-purple'
  }

  //Blue Color
  if (pastDueWithFutureForecast(milestone)) {
    return 'hc-pa-md cpm-milestone-blue'
  }
  return 'hc-pa-md'
}

export function getMilestoneStatus(milestone: iScheduleMilestone) {
  // Green
  if (completedOnTime(milestone)) {
    return 'Completed'
  }

  // Orange
  if (completedLate(milestone)) {
    return 'Completed Late'
  }

  // Red
  if (pastDue(milestone)) {
    return 'Past Due'
  }

  // Purple
  if (dueInTwoWeeks(milestone)) {
    return 'Pending'
  }

  // Blue
  if (pastDueWithFutureForecast(milestone)) {
    return 'Delayed'
  }
  return 'Open'
}

// Milestones are organized from most recently due to last due.
// milestones with an Actual date filled in can move to the bottom of the list. (If forecast not available, use plan)
// Any past due milestones without an actual should be at the top of the list
// Any milestones with actuals falls to the bottom.
export function sortMilestonesByDate(milestones: iScheduleMilestone[]) {
  if (!milestones || milestones?.length < 1) {
    return []
  }
  let milestoneCopy = [...milestones]
  let indexesAlreadyProcessed: number[] = []

  const completedOnTimeMilestones = milestoneCopy
    .filter((milestone: iScheduleMilestone, index: number) => {
      if (completedOnTime(milestone)) {
        indexesAlreadyProcessed.push(index)
        return true
      }
      return false
    })
    .sort((a, b) =>
      a?.actual_end_date! > b?.actual_end_date!
        ? 1
        : b?.actual_end_date! > a?.actual_end_date!
          ? -1
          : 0,
    )

  const completedLateMilestones = milestoneCopy
    .filter((milestone: iScheduleMilestone, index: number) => {
      if (
        completedLate(milestone) &&
        !indexesAlreadyProcessed.includes(index)
      ) {
        indexesAlreadyProcessed.push(index)
        return true
      }
      return false
    })
    .sort((a, b) =>
      a?.actual_end_date! > b?.actual_end_date!
        ? 1
        : b?.actual_end_date! > a?.actual_end_date!
          ? -1
          : 0,
    )

  const milestonesPastDue = milestoneCopy
    .filter((milestone: iScheduleMilestone, index: number) => {
      if (pastDue(milestone) && !indexesAlreadyProcessed.includes(index)) {
        indexesAlreadyProcessed.push(index)
        return true
      }
      return false
    })
    .sort((a, b) =>
      (a?.forecasted_end_date || a?.planned_end_date)! >
      (b?.forecasted_end_date || b?.planned_end_date)!
        ? 1
        : (b?.forecasted_end_date || b?.planned_end_date)! >
            (a?.forecasted_end_date || a?.planned_end_date)!
          ? -1
          : 0,
    )

  const milestonesDueInTwoWeeks = milestoneCopy
    .filter((milestone: iScheduleMilestone, index: number) => {
      if (
        dueInTwoWeeks(milestone) &&
        !indexesAlreadyProcessed.includes(index)
      ) {
        indexesAlreadyProcessed.push(index)
        return true
      }
      return false
    })
    .sort((a, b) =>
      (a?.forecasted_end_date || a?.planned_end_date)! >
      (b?.forecasted_end_date || b?.planned_end_date)!
        ? 1
        : (b?.forecasted_end_date || b?.planned_end_date)! >
            (a?.forecasted_end_date || a?.planned_end_date)!
          ? -1
          : 0,
    )

  const milestonesPastDueWithForecastDateInFuture = milestoneCopy
    .filter((milestone: iScheduleMilestone, index: number) => {
      if (
        pastDueWithFutureForecast(milestone) &&
        !indexesAlreadyProcessed.includes(index)
      ) {
        indexesAlreadyProcessed.push(index)
        return true
      }
      return false
    })
    .sort((a, b) =>
      a?.planned_end_date! > b?.planned_end_date!
        ? 1
        : b?.planned_end_date! > a?.planned_end_date!
          ? -1
          : 0,
    )

  const remainingMilestonesWithPlannedDates = milestones
    .filter((milestone: iScheduleMilestone, index: number) => {
      if (
        hasOnlyPlannedDate(milestone) &&
        !indexesAlreadyProcessed.includes(index)
      ) {
        indexesAlreadyProcessed.push(index)
        return true
      }
      return false
    })
    .sort((a, b) =>
      a?.planned_end_date! > b?.planned_end_date!
        ? 1
        : b?.planned_end_date! > a?.planned_end_date!
          ? -1
          : 0,
    )

  const remainingMilestones = milestones.filter(
    (milestone: iScheduleMilestone, index: number) => {
      if (!indexesAlreadyProcessed.includes(index)) {
        return true
      }
      return false
    },
  )

  return [
    ...milestonesPastDue,
    ...milestonesDueInTwoWeeks,
    ...milestonesPastDueWithForecastDateInFuture,
    ...remainingMilestonesWithPlannedDates,
    ...completedLateMilestones,
    ...completedOnTimeMilestones,
    ...remainingMilestones,
  ]
}
