import { Card } from '@enterprise-ui/canvas-ui-react'
import SkeletonCpm from './SkeletonCpm'

const AgGridLoading = () => {
  return (
    <div>
      {[...Array(100)].map((val, index) => (
        <div className="hc-mt-md" key={index}>
          <Card className="hc-pa-md ag-grid-loading-card-1">
            <div>
              <SkeletonCpm width="80vw" />
              <SkeletonCpm width="80vw" />
            </div>
          </Card>
        </div>
      ))}
    </div>
  )
}

export default AgGridLoading
