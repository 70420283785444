import { useRoutes } from 'react-router-dom'
import Locations from '../views/Locations'
import Projects from '../views/Projects'
import constants from '../utilities/constants'
import AppHeader from '../components/PageHeader'
import Milestones from '../views/Milestones'
import MilestoneDetails from '../views/MilestoneDetails'
import PageNotFound from '../views/PageNotFound'
import { useAppContext } from '../Context'
import ErrorPage from '../views/ErrorPage'

export const MainRouter: React.FC = () => {
  const { pageNotFound, networkError } = useAppContext()
  let routes = useRoutes([
    {
      path: '*',
      element: <PageNotFound />,
    },
    {
      path: '/',
      element: <Locations />,
      children: [],
    },
    { path: constants.LOCATION_PATH, element: <Locations />, children: [] },
    { path: constants.PROJECT_PATH, element: <Projects />, children: [] },
    { path: constants.MILESTONES_PATH, element: <Milestones />, children: [] },
    {
      path: constants.MILESTONE_DETAILS_PATH,
      element: <MilestoneDetails />,
      children: [],
    },
  ])
  return networkError ? (
    <ErrorPage />
  ) : (
    <>
      {!pageNotFound && <AppHeader />}
      {routes}
    </>
  )
}
