import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import '@enterprise-ui/canvas-ui-css'
import '@enterprise-ui/canvas-ui-css-autocomplete'
import '@enterprise-ui/canvas-ui-css-datepicker'
import 'react-loading-skeleton/dist/skeleton.css'
import { AuthProvider } from '@praxis/component-auth'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import './App.scss'
import { Main } from './views/Main'
import apiConfig from './apiConfig'
import { ToastProvider } from '@enterprise-ui/canvas-ui-react'
import { AppContextProvider } from './Context'
import constants from './utilities/constants'

// Capital Projects Mobile Entry Point
export const App: React.FC = () => {
  // POL Auth flow change - apiConfig auth properties for POL login
  if (window?.location?.hostname?.includes?.('partnersonline')) {
    apiConfig.auth = {
      ...apiConfig.authPol,
    }
  }

  React.useEffect(() => {
    if (window.location.pathname === '/') {
      window.history.replaceState('', '', constants.MOBILE_BASE_PATH)
    }
  }, [])

  return (
    <>
      <AppContextProvider>
        <AuthProvider {...apiConfig.auth}>
          <Router basename={constants.MOBILE_BASE_PATH}>
            <ToastProvider location="top">
              <Main />
            </ToastProvider>
          </Router>
        </AuthProvider>
      </AppContextProvider>
    </>
  )
}
