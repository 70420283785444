import * as React from 'react'
import {
  LocationProps,
  ProjectForLocationProps,
  iMilestone,
  iSchedule,
  iSelectedMilestone,
  iScheduleMilestone,
  iScheduleTemplate,
  iMilestoneAdditionalAttributes,
  iAssignedSource,
} from './types/types'
import { AutocompleteOptions } from '@enterprise-ui/canvas-ui-react-autocomplete'
import useWindowSize from './utilities/useMediaQuery'
import { useState } from 'react'

export const AppContext = React.createContext<Partial<Props>>({})

// Add any new Context to the interface
interface Props {
  heading: string
  setHeading: Function
  locations: LocationProps | null
  setLocations: Function
  allProgramTypes: AutocompleteOptions[] | null
  setAllProgramTypes: Function
  allProjectsForLocation: ProjectForLocationProps[] | null
  setAllProjectsForLocation: Function
  allAssignedSources: iAssignedSource[] | null
  setAllAssignedSources: Function
  selectedLocation: string
  setSelectedLocation: Function
  selectedProgramName: string
  setSelectedProgramName: Function
  selectedProgramType: AutocompleteOptions | null
  setSelectedProgramType: Function
  projects: ProjectForLocationProps[] | null
  setProjects: Function
  allScheduleViewTypes: AutocompleteOptions[] | null
  setAllScheduleViewTypes: Function
  selectedScheduleViewType: AutocompleteOptions | null
  setSelectedScheduleViewType: Function
  allMilestones: iMilestone[] | null
  setAllMilestones: Function
  projectSchedules: iSchedule | null
  setProjectSchedules: Function
  selectedMilestone: iSelectedMilestone | null
  setSelectedMilestone: Function
  scheduleMilestoneToEdit: iScheduleMilestone | null
  setScheduleMilestoneToEdit: Function
  isDesktop: boolean
  reload: boolean
  setReload: Function
  scheduleMilestoneFiles: any
  setScheduleMilestoneFiles: Function
  darkMode: any
  setDarkMode: Function
  isExternal: boolean
  pageNotFound: boolean
  setPageNotFound: Function
  networkError: boolean
  setNetworkError: Function
  allScheduleTemplates: iScheduleTemplate[] | null
  setAllScheduleTemplates: Function
  milestoneActualOwners: AutocompleteOptions[] | null
  setMilestoneActualOwners: Function
  selectedMilestoneOwner: any
  setSelectedMilestoneOwner: Function
  reasonsForDelay: AutocompleteOptions[] | null
  setReasonsForDelay: Function
  selectedReasonForDelay: AutocompleteOptions | null
  setSelectedReasonForDelay: Function
  milestoneAttributes: iMilestoneAdditionalAttributes[] | null
  setMilestoneAttributes: Function
}

export function useAppContext() {
  return React.useContext(AppContext) as Props
}

export const AppContextProvider: React.FC<ProviderProps> = ({ children }) => {
  const [heading, setHeading] = React.useState('')
  const [allProgramTypes, setAllProgramTypes] = React.useState(null)
  const [locations, setLocations] = React.useState(null)
  const [allProjectsForLocation, setAllProjectsForLocation] =
    React.useState(null)
  const [allAssignedSources, setAllAssignedSources] = React.useState(null)
  const [selectedLocation, setSelectedLocation] = React.useState('')
  const [selectedProgramName, setSelectedProgramName] = React.useState('')

  const [selectedProgramType, setSelectedProgramType] =
    React.useState<AutocompleteOptions | null>(null)
  const [selectedReasonForDelay, setSelectedReasonForDelay] =
    React.useState<AutocompleteOptions | null>(null)
  const [projects, setProjects] = React.useState<
    ProjectForLocationProps[] | null
  >(null)
  const [allScheduleViewTypes, setAllScheduleViewTypes] = React.useState(null)
  const [reasonsForDelay, setReasonsForDelay] = React.useState(null)
  const [selectedScheduleViewType, setSelectedScheduleViewType] =
    React.useState<AutocompleteOptions | null>(null)
  const [allMilestones, setAllMilestones] = React.useState<iMilestone[] | null>(
    null,
  )
  const [projectSchedules, setProjectSchedules] =
    React.useState<iSchedule | null>(null)
  const [selectedMilestone, setSelectedMilestone] =
    React.useState<iSelectedMilestone | null>(null)
  const [width] = useWindowSize()
  const [scheduleMilestoneToEdit, setScheduleMilestoneToEdit] =
    React.useState<iScheduleMilestone | null>(null)
  const [reload, setReload] = React.useState<boolean>(false)
  const [scheduleMilestoneFiles, setScheduleMilestoneFiles] =
    React.useState<any>([])
  const localStorageThemeValue =
    window?.localStorage?.getItem?.('darkMode') ?? false
  const isOsDarkMode = window
    .matchMedia('(prefers-color-scheme: dark)')
    .matches.toString()
  const [darkMode, setDarkMode] = useState<any>(
    localStorageThemeValue === false ? isOsDarkMode : localStorageThemeValue,
  )
  const [pageNotFound, setPageNotFound] = React.useState<boolean>(false)
  const isExternal = window?.location?.hostname?.includes?.('partnersonline')
  const isDesktop = width > 768
  const [networkError, setNetworkError] = React.useState<boolean>(false)
  const [allScheduleTemplates, setAllScheduleTemplates] = React.useState(null)
  const [milestoneActualOwners, setMilestoneActualOwners] = React.useState(null)
  const [selectedMilestoneOwner, setSelectedMilestoneOwner] =
    React.useState(null)
  const [milestoneAttributes, setMilestoneAttributes] = React.useState<
    iMilestoneAdditionalAttributes[] | null
  >(null)
  return (
    <AppContext.Provider
      value={{
        heading,
        setHeading,
        locations,
        setLocations,
        allProgramTypes,
        setAllProgramTypes,
        allProjectsForLocation,
        setAllProjectsForLocation,
        allAssignedSources,
        setAllAssignedSources,
        selectedLocation,
        setSelectedLocation,
        selectedProgramName,
        setSelectedProgramName,
        selectedProgramType,
        setSelectedProgramType,
        projects,
        setProjects,
        allScheduleViewTypes,
        setAllScheduleViewTypes,
        selectedScheduleViewType,
        setSelectedScheduleViewType,
        allMilestones,
        setAllMilestones,
        projectSchedules,
        setProjectSchedules,
        selectedMilestone,
        setSelectedMilestone,
        scheduleMilestoneToEdit,
        setScheduleMilestoneToEdit,
        isDesktop,
        reload,
        setReload,
        scheduleMilestoneFiles,
        setScheduleMilestoneFiles,
        darkMode,
        setDarkMode,
        isExternal,
        pageNotFound,
        setPageNotFound,
        networkError,
        setNetworkError,
        allScheduleTemplates,
        setAllScheduleTemplates,
        milestoneActualOwners,
        setMilestoneActualOwners,
        selectedMilestoneOwner,
        setSelectedMilestoneOwner,
        reasonsForDelay,
        setReasonsForDelay,
        selectedReasonForDelay,
        setSelectedReasonForDelay,
        milestoneAttributes,
        setMilestoneAttributes,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export interface ProviderProps {
  children?: React.ReactNode
}
